import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { OverlayModal } from "../../Theme/Overlay/Overlay";
import { AppButton } from "../../Theme/Shared/AppButton";
import { useMutation } from "react-query";
import { setAcceptTermsAndConditions } from "../../QueryHook/User/Profile";
import { MainContext } from "../../context/MainProvider";
import { useTranslation } from "react-i18next";

const TermsAndConditionsScreen = (props: { uuid: string }) => {

    const navigate = useNavigate();
    const { logout } = useAuth0();
    const { t } = useTranslation();
  
    const {mainState} = useContext(MainContext)
  
    const submitAcceptTermsAndConditions = useMutation(() =>
      setAcceptTermsAndConditions(props.uuid)
    );
  
    const approveHandler = () => {
      if (props.uuid) {
        submitAcceptTermsAndConditions.mutate();
        localStorage.setItem("termsAndConditions", "true");
        return navigate("/stateFlow");
      }
    };
  
    const declineHandler = () => {
      logout({ returnTo: window.location.origin });
    };
  
    return (
      <div style={{ backgroundColor:mainState.backgroundColor }} className="flex flex-col items-center justify-center flex-1 w-full h-full gap-5 ">
        <OverlayModal isOpen onClose={() => {}}>
          <div className="flex flex-col items-center justify-between w-3/5 px-4 py-5 bg-white rounded-md terms-condition">
            <img src={mainState.logo} alt="" className="mb-1 w-28" />
            <h3 className="font-bold text-[#eb004e]">{t('termsOfUse')}</h3>
            <div className="flex flex-col mt-4">
              <p className="my-2 text-xs text-[#303539]">
                The user acknowledges that accessing this application is
                voluntary, and not a requirement for employment consideration or
                as a term or condition of employment. Utilization of this
                application is for the user’s benefit, not for the benefit of
                {mainState.organization.name} and does not constitute work and is not performed in
                furtherance of any work-related tasks.
              </p>
              <p className="my-2 text-xs text-[#303539]">
                The user also acknowledges that by downloading the {mainState.organization.name} START
                application to their personal mobile device that they are
                consenting to communication by {mainState.organization.name}, including app
                notifications and SMS messaging. Carrier message and data rates
                may apply.
              </p>
            </div>
  
            <hr className="w-full h-px my-3 border-gray-100 border-1" />
            <div className="flex items-center justify-between w-full">
              <AppButton
                type="button"
                variant="light"
                extendClass="w-full"
                size="xs"
                onClick={declineHandler}
              >
                Decline
              </AppButton>
              <AppButton
                type="button"
                variant="info"
                extendClass="w-full"
                size="xs"
                onClick={approveHandler}
              >
                Accept and Continue
              </AppButton>
            </div>
            <p className="my-2 text-xs text-[#303539] text-center mt-4">
              By clicking Accept and Continue you also accept these{" "}
              <a
                href="https://www.actionlogics.com/tos"
                className="text-blue-600"
                target="_blank"
                rel="noreferrer"
              >
                 {t('termsOfUse')}
              </a>
              .
            </p>
          </div>
        </OverlayModal>
      </div>
    );
  };


export default TermsAndConditionsScreen  