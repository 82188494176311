import React, { FC, memo, useContext, useRef, useState } from 'react';
// import { getAllTags } from "../QueryHook/User/Profile";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import { store } from "../store/store";
import { MainContext } from '../context/MainProvider';
import { useTranslation } from 'react-i18next';

import NewContent from './BottomSheet/NewContent';
import SeenContent from './BottomSheet/SeenContent';
import SearchContent from './BottomSheet/SearchContent';

type props = {
    isContentsModalOpen: boolean
    isSheetOpen: boolean
    participantId?: number
    participantSlug?: string | null
    pathwaySlug?: string
    pathwayID?: number
    handleContentsModal: (id?: number, type?: string, isInteracted?: boolean, contentSlug?: string) => void
}

const BottomSheetContainer: FC<props> = memo(({
    isContentsModalOpen,
    isSheetOpen,
    participantId,
    pathwayID,
    handleContentsModal,
    participantSlug,
    pathwaySlug
}) => {

    const { t, i18n } = useTranslation();
    
    const { state } = useContext(store);

    const { mainState, setMainState } = useContext(MainContext)


    const uuid = state.user?.sub || ""

    const [tab, setTab] = useState<'new' | 'seen' | 'search'>('new')
    // const [selectedTag, setTag] = useState<undefined | string>(undefined)

    const focusRef = useRef() as any;
    const sheetRef = useRef() as any;


    // const userTags = useQuery<Worker_Tags, Error>(['user-tags', uuid, participantSlug], () => getAllTags(1, uuid, participantSlug ?? ""), {
    //     enabled: pathwaySlug !== "" && tab === 'search',
    // })

    return (
        <BottomSheet
            open={!isContentsModalOpen && isSheetOpen && !mainState.isInstalledPWA}
            skipInitialTransition
            ref={sheetRef}
            initialFocusRef={focusRef}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ maxHeight }) => [maxHeight / 1.7, maxHeight * 0.85]}
            blocking={false}
            className="z-10"
            expandOnContentDrag={true}
        >
            <div
                className="relative flex-1 h-full px-4 py-12 -mt-16 bg-white rounded-t-main-content">
                <ul className="flex items-center self-start p-0 mt-6 ml-4">

                    {/*-------------------------------------------------------------------*/}
                    {/*New content tab*/}
                    <li role='button' className="flex flex-col items-center mr-4 text-xs font-bold text-general-text"
                    //className={`${tab === 'new' ? 'text-general-text' : 'text-gray-400'} font-bold text-xs mr-4 flex flex-col items-center`}
                    >
                        <button type='button' onClick={() => setTab('new')}>
                            {t('new')}
                            {
                                tab === "new" ?
                                    <div
                                        className="w-full px-6 bg-general-text bottom-0 rounded-full h-0.5 mt-1" /> :
                                    <div className="w-2 h-0.5 rounded-full bg-gray-300 bottom-0 mt-1" />
                            }
                        </button>
                    </li>

                    {/*-------------------------------------------------------------------*/}
                    {/*Seen content tab*/}
                    <li role="button" className="flex flex-col items-center mr-4 text-xs font-bold text-general-text"
                    >
                        <button type='button' onClick={() => setTab('seen')}>
                            {t('seen')}
                            {
                                tab === "seen" ?
                                    <div
                                        className="w-full px-6 bg-general-text bottom-0 rounded-full h-0.5 mt-1" /> :
                                    <div className="w-2 h-0.5 rounded-full bg-gray-300 bottom-0 mt-1" />
                            }
                        </button>
                    </li>

                     {/*Search content tab*/}
                     {/* <li role="button" className="flex flex-col items-center mr-4 text-xs font-bold text-general-text"
                    >
                        <button type='button' onClick={() => setTab('search')}>
                            {t('search')}
                            {
                                tab === "search" ?
                                    <div
                                        className="w-full px-6 bg-general-text bottom-0 rounded-full h-0.5 mt-1" /> :
                                    <div className="w-2 h-0.5 rounded-full bg-gray-300 bottom-0 mt-1" />
                            }
                        </button>
                    </li> */}

                    {/*-------------------------------------------------------------------*/}
                    {/*Search content tab*/}
                    {/*<li className="flex flex-col items-center mr-4 text-xs font-bold text-general-text"*/}
                    {/*    //className={`${tab === 'search' ? 'text-general-text' : 'text-gray-400'} font-bold text-xs mr-4 flex flex-col items-center`}*/}
                    {/*    onClick={() => setTab('search')}>*/}
                    {/*    SEARCH*/}
                    {/*    {*/}
                    {/*        tab === "search" ?*/}
                    {/*            <div*/}
                    {/*                className="w-full px-6 bg-general-text bottom-0 rounded-full h-0.5 mt-1"/> :*/}
                    {/*            <div className="w-2 h-0.5 rounded-full bg-gray-300 bottom-0 mt-1"/>*/}
                    {/*    }*/}
                    {/*</li>*/}

                </ul>    
                {
                    tab === 'new' && participantSlug ?
                    <NewContent participantSlug={participantSlug} uuid={uuid} onhandleContentsModal={handleContentsModal} /> : null
                }

                {
                    tab === 'seen' && participantSlug ?
                    <SeenContent participantSlug={participantSlug} uuid={uuid} onhandleContentsModal={handleContentsModal} />
                    : null
                }
                {/* {
                    tab === 'search' && participantSlug ?
                    <SearchContent participantSlug={participantSlug} uuid={uuid} />
                    : null
                } */}
            </div>
        </BottomSheet>
    );
});

export default BottomSheetContainer;