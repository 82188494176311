import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import logo from "./../../assets/logo192_start.png";
import { Spinner } from "../../Theme/Shared/AppButton/AppButtonSpinner";
import {
  getWorkerProfile,
} from "../../QueryHook/User/Profile";
import { getToken, setToken } from "../../utils/LocalStorage";
import { MainContext } from "../../context/MainProvider";
import { useTranslation } from "react-i18next";
import TermsAndConditionsScreen from "./TermsAndConditionsScreen";
import { useQuery } from "react-query";


const Splash = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mainState } = useContext(MainContext)

  const isPWAInstalled = useMemo(() => {
    // Check if running in standalone mode on iOS
    if (
      (navigator as any).standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const handleNavigation = async () => {
      if (!navigator.onLine) {
        if (isPWAInstalled) return navigate("/stateFlow");
      }
      if (navigator.onLine) {
        if (isAuthenticated) {
          return navigate("/stateFlow");
        }
        if (!isLoading && !isAuthenticated) {
          return await loginWithRedirect();
        }
      }
    };

    handleNavigation();
  }, [isAuthenticated, isLoading, isPWAInstalled, loginWithRedirect, navigate]);

  return (
    <div style={{ backgroundColor:mainState.backgroundColor }} className="flex flex-col items-center justify-center flex-1 w-full h-full gap-5">
      <img
        src={logo}
        className="w-16 h-16 rounded-md shadow-sm"
        alt="ActionLogics"
      />
      <div className="h-32">
        <Spinner height={40} />
      </div>
    </div>
  );
};

const SplashScreen = () => {
  const { isAuthenticated, user, loginWithRedirect, isLoading, getIdTokenClaims, logout } = useAuth0();
  const { setMainState, mainState } = useContext(MainContext)

  // const [isLoadingUserDetails, setIsLoadingUserDetails] = useState<boolean>(true);

  const [auth0 ,setAuth0] = useState({
    uuid:'',
    token:''
  })

  //  const { isLoading: isLoadingWorkerProfile, refetch } =  useQuery(['user-profile-data', user?.sub],
  //   () => getWorkerProfile(auth0.uuid, auth0.uuid), {
  //   enabled: auth0.uuid !== '' && auth0.token !== '',
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   refetchOnReconnect: false,
  //   retry: false,
  //   staleTime: Infinity,
  //   cacheTime: Infinity,
  //   onSuccess: data => {
  //     setUserDetails(data.data)
  //     console.log('user_query_data', data)
  //     // setIsLoadingUserDetails(false)
  //   }
  // })   
  const { isLoading: isLoadingWorkerProfile, refetch, data: userProfileData } = useQuery(
    ['user-profile-data', auth0.uuid], // Use auth0.uuid as the key
    () => getWorkerProfile(auth0.uuid, auth0.token),
    {
      enabled: auth0.uuid !== '' && auth0.token !== '',
      // other options...
      onSuccess: data => {
        console.log('user_query_data', data)
      }
    }
  );



  useEffect(() => {
    getIdTokenClaims()
      .then(async (response) => {
        if (user !== undefined && user.sub !== undefined && response !== undefined) {
          const token = response?.__raw;
          setAuth0({
            uuid: user.sub,
            token: token,
          });
          setToken(token);
          // setIsLoadingUserDetails(false);
          refetch();
        }
      })
      .catch((error) => {
        console.log('error', error);
        // setIsLoadingUserDetails(false);
      });
  }, [getIdTokenClaims, user]);

  useEffect(() => {
    const checkAuth = async () => {
      if (navigator.onLine) {
        if (!isLoading && !isAuthenticated) {
          return await loginWithRedirect();
        }
      }
    };

    checkAuth();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // if (isLoadingUserDetails) {
  //   // Render a loading state or skeleton component while user details are being fetched
  //   return (
  //     <div className="h-32">
  //       <Spinner height={40} />
  //     </div>
  //   );
  // }
  if (isLoadingWorkerProfile) {
    // Render a loading state or skeleton component while user details are being fetched
    return (
      <div className="h-32">
        <Spinner height={40} />
      </div>
    );
  }

  if (!isAuthenticated)
    return (
      <div style={{ backgroundColor:mainState.backgroundColor }} className="flex flex-col items-center justify-center flex-1 w-full h-full gap-5 ">
        <img
          src={logo}
          className="w-16 h-16 rounded-md shadow-sm"
          alt="ActionLogics"
        />
        <div className="h-32">
          <Spinner height={40} />
        </div>
      </div>
    );
   
  return (
    <>
      {/* {isAuthenticated && userDetails?.data?.terms_accepted ? ( */}
      {isAuthenticated && userProfileData?.data?.terms_accepted ? (
        <Splash />
      ) : (
        <TermsAndConditionsScreen uuid={user?.sub || ""} />
      )}
    </>
  );
};

export default SplashScreen;
