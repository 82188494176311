export const DirectionIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
      viewBox="0 96 960 960"
    >
      <path fill="currentColor" d="M330.001 685.999h45.383V558.692h192.078v74.614L665.768 536l-98.306-98.691v75.999H358.847q-12.519 0-20.683 8.119-8.163 8.12-8.163 20.573v143.999zm150.075 266.845q-11.307 0-22.216-4t-18.937-12L119.156 617.077q-8-7.984-12-18.839-4-10.856-4-22.162 0-11.307 4-22.216t12-18.937l319.767-319.767q7.984-8 18.839-12 10.856-4 22.162-4 11.307 0 22.216 4t18.937 12l319.767 319.767q8 7.984 12 18.839 4 10.856 4 22.162 0 11.307-4 22.216t-12 18.937L521.077 936.844q-7.984 8-18.839 12-10.856 4-22.162 4z"></path>
    </svg>
  )
}