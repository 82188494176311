import { FC, useContext, useLayoutEffect, useRef } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
// import logo from "./../../assets/fedex.png";
import { MainContext } from "../../context/MainProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const NotFoundScreen: FC = () => {
  const focusRef = useRef() as any;
  const sheetRef = useRef() as any;
  const { mainState } = useContext(MainContext)
  const { isAuthenticated } = useAuth0();
  const navigator = useNavigate()

  if (isAuthenticated && mainState.allWorkerParticipants.length > 0) {
    navigator('/stateFlow')
    return <></>
  }
  return (
    <div style={{ backgroundColor: mainState.backgroundColor }} className="flex flex-col items-center justify-between flex-1 w-full h-full gap-5 ">
      <div className="mt-20">
        <img src={mainState.logo} alt="FedEx" className="h-[60%]" />
      </div>
      <div>
        <BottomSheet
          open={true}
          skipInitialTransition
          ref={sheetRef}
          initialFocusRef={focusRef}
          defaultSnap={({ maxHeight }) => maxHeight / 2}
          snapPoints={({ maxHeight }) => [maxHeight / 1.4, maxHeight * 0.85]}
          blocking={false}
          className="rounded-t-2xl"
          expandOnContentDrag={false}
        >
          <div className="relative flex-1 h-full px-2 py-16 bg-white rounded-t-main-content">
            <h1 className="pb-20 mb-8 text-xl font-thin leading-normal text-center">
             We are having issues accessing your data. Please refresh your browser or try logging in again later at <br />
              <a target="_blank"
                rel="noreferrer" href="https://start.actionlogics.com/" className="text-blue-700">
                https://start.actionlogics.com/</a>
            </h1>

            <p className="px-6 my-8 text-center text-gray-600 text-md">
              Continue to have issues? Click <a
                href="https://support-emp.actionlogics.com/"
                className="w-full text-blue-700 uppercase text-md"
                target="_blank"
                rel="noreferrer"
              >here </a> if you're an employee or click <a
                href="https://careers.fedex.com/express-support/"
                className="w-full text-blue-700 uppercase text-md"
                target="_blank"
                rel="noreferrer"
              >here </a> if you're a candidate. </p>
          </div>
        </BottomSheet>
      </div>
    </div>
  );
};

export default NotFoundScreen;
