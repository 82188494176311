import React, { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { Content_Individual } from "../../types/User";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { MdLocationPin } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../context/MainProvider";

const Direction: FC<{ content: Content_Individual }> = memo(({ content }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string
  });

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;



  const address = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.direction.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.direction[0].address;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.direction.filter(
        (item: any) => item.language === currentLanguage
      )[0].address
    } else return content?.data?.direction?.address;
  }, [content, currentLanguage]);

  // const address = useMemo(() => {
  //   if (content.data.template_id) {
  //     return content.data.content_template.direction[0].address;
  //   } else return content?.data?.direction?.address;
  // }, [content]);

  if (!isLoaded) return <div>Loading...</div>;
  return <Map address={address || ""} />;
});

const Map: FC<{ address: string }> = ({ address }) => {
  const { t } = useTranslation();
  const [marker, setMarker] = useState({ lat: 44, lng: -80 });
  const { mainState} = useContext(MainContext)

  useEffect(() => {
    const getCoordinates = async () => {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      setMarker({ lat, lng });
    };
    getCoordinates();
  }, []);

  return (
    <>
      <GoogleMap
        zoom={10}
        center={marker}
        mapContainerClassName="map-container"
      >
        <Marker position={marker} />
      </GoogleMap>

      <br />

      <span
        onClick={() =>
          window.open(`https://maps.google.com/?q=${address}`, "_blank")
        }
        style={{ backgroundColor:mainState.baseColor }}
        className="flex items-center justify-center pt-2 pb-2 pl-4 pr-4 mt-2 rounded-md cursor-pointer "
      >
        <MdLocationPin className="mr-2" />
        {t("navigatetothisaddress")}
      </span>
    </>
  );
};

export default Direction;
