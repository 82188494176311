import React, { createContext, useState } from "react";
import { SurveySequenceType } from "../types/SurveySequenceTypes";
import actionLogicLogo from './../assets/logo192.png'
export interface MainStateType {
  currentPathwaySlug:string
  openContentPage: boolean;
  activate?: boolean;
  step?: number;
  // contentId?: number
  contentSlug?: string;
  surveySequence?: SurveySequenceType | null;
  // surveyContentId: number,
  surveyContentSlug: string;
  surveys: any;
  isInstalledPWA: boolean;
  currentParticipantSlug: string;
  backgroundColor:string
  baseColor:string
  logo:string
  organization_id:number
  allWorkerParticipants: any[]
  installAppFromHome:boolean,
  organization: {
    name:string,
    candidate_support_link: string,
    employee_support_link:string,
    terms_of_service: string
  },
  user: {
    uuid:string
  }
}

const initialMainState: MainStateType = {
  activate: false,
  openContentPage: false,
  step: 0,
  contentSlug: "",
  surveySequence: null,
  surveyContentSlug: "",
  surveys: null,
  isInstalledPWA: false,
  currentPathwaySlug: "",
  currentParticipantSlug: "",
  backgroundColor:"#4D148C",
  baseColor:"#FF6600",
  logo: actionLogicLogo,
  organization_id: 0,
  allWorkerParticipants: [],
  installAppFromHome:false,
  organization: {
    name:"ActionLogics",
    candidate_support_link: "",
    employee_support_link:"",
    terms_of_service: ""
  },
  user: {
    uuid:""
  }
};

export type ContextType = {
  mainState: MainStateType;
  setMainState: React.Dispatch<React.SetStateAction<MainStateType>>;
};

export const MainContext = createContext<ContextType>({
  mainState: initialMainState,
  setMainState: () => {},
});

interface MainProviderProps {
  children: React.ReactNode;
}

export const MainProvider: React.FC<MainProviderProps> = ({ children }) => {
  const [mainState, setMainState] = useState<MainStateType>(initialMainState);

  const value = { mainState, setMainState };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
