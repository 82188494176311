import React, { useContext } from "react"
import { MainContext } from "../../context/MainProvider"


export interface SurveySequenceHeaderProps {
    tagTitle: string
    title: string
    description?: string
    imgUrl?: string
    onErrorLoadImg?: React.ReactEventHandler<HTMLImageElement>
}

const SurveySequenceHeader: React.FC<SurveySequenceHeaderProps> = ({ tagTitle, title, description, imgUrl, onErrorLoadImg }) => {
    const {mainState} = useContext(MainContext)
    return <React.Fragment>
        <span
            style={{ backgroundColor:mainState.baseColor }}
            className="text-white text-xs px-1 py-0.5 my-2 rounded  uppercase font-bold">{tagTitle}</span>
        <span className="pb-5 text-xl text-center">{title}</span>
        {description && <span
            className="pb-2 text-sm text-center">{description}</span>}
        <hr className="border-[1px] border-gray-200 w-full my-5 opacity-10" />

        {imgUrl && onErrorLoadImg ? <img className="w-full shadow-md aspect-video rounded-t-md"
            src={imgUrl}
            onError={onErrorLoadImg}
            alt="" /> : null}
    </React.Fragment>
}
export default SurveySequenceHeader