import {Navigate} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";
import {Loader} from "../General/Loader";
import {useContext, useEffect} from "react";
import {setToken} from "../../utils/LocalStorage";
import {store} from "../../store/store";
import {setUser} from "../../store/store.actions";

export type ProtectedRouteProps = {
    isAuthenticated: boolean;
    authenticationPath: string;
    outlet: JSX.Element;
};

export default function ProtectedRoute({isAuthenticated, authenticationPath, outlet}: ProtectedRouteProps) {

    const {isLoading, getAccessTokenSilently, user,getIdTokenClaims} = useAuth0();
    const {dispatch} = useContext(store);

    useEffect(() => {
        if (user) {
            //"auth0|62b07df9aa96951da8c48c26"
            //"auth0|62ab1de815f8eea7bbd7c6ec"
            //"auth0|62ab1de815f8eea7bbd7c6ec"
            // user.sub = "auth0|631798039942a8ee8e49a0ea"
                //"auth0|62ba6d17e81078c421094208"
            dispatch(setUser(user))
        }
    }, [user]);
    // used this code there is issue on access token not have user email
    useEffect(() => {
        getIdTokenClaims().then(res => {
           // console.log(res?.__raw)
            setToken(res?.__raw as string)
        })
    }, [getIdTokenClaims])

    // old code now work ask haitham , mostafa
    /*
    useEffect(() => {
        getAccessTokenSilently().then(res => {
            console.log(res)
            setToken(res)
        })
    }, [getAccessTokenSilently])
    */

    if (isLoading) return <Loader/>

    if (isAuthenticated) {
        return outlet;
    } else {
        return <Navigate to={{pathname: authenticationPath}}/>;
    }
};