import API from "../index";
import axios from "axios";
import { getValue, setValue } from "../../services/offline";
import { getCurrentParticipantSlug, getToken } from "../../utils/LocalStorage";

const apiURL = process.env.REACT_APP_API_URL

export const getWorkerProfile = async (uuid: string, token: string) => {
  // console.log('BEFORE sending request')
  const { data } = await axios.get(`${apiURL}users/${uuid}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const getWorkerCurrentPathway = async (
  organ: number,
  uuid: string,
  participantSlug: string
) => {
  if (navigator.onLine && organ !== undefined) {
    let slug = participantSlug || getCurrentParticipantSlug();

    const { data } = await API.get(
      `/users/${uuid}/current-pathway?organization=${organ}&participant=${slug}`
    );

    return data;
  } else {
    let offline_data = await getValue("all-states");
    if (offline_data) {
      return offline_data;
    }
  }
};

export const getWorkerCurrentState = async (
  uuid: string,
  pathwaysId: number
) => {
  const { data } = await API.get(
    `/users/${uuid}/current-state?pathway=${pathwaysId}`
  );
  return data;
};

export const getAllWorkers = async (organ: number) => {
  const { data } = await API.get(
    `/admin/organizations/${organ}/pathways/${1}/states/${1}/contents/${1}`
  );
  // const {data} = await API.get('/admin/users');
  return data;
};

export const getWorkerPathways = async (uuid: string, token: string) => {
  // console.log('daniel url', `${apiURL}users/${uuid}/pathways`)
  const _token = token || getToken();
  if (navigator.onLine) {
    const response = await axios.get(`${apiURL}users/${uuid}/pathways`, {
      headers: { Authorization: `Bearer ${_token}` },
    });
    // console.log('daniel response', response)
    return response.data;
  } else {
    let offline_data = await getValue("pathway-list");
    if (offline_data) {
      return offline_data;
    }
  }
};

export const getAllTags = async (
  organ: number,
  uuid: string,
  pathwaySlug: string
) => {
  if (navigator.onLine) {
    const { data } = await API.get(
      `/users/${uuid}/content-tags?organization=${organ}&pathway=${pathwaySlug}`
    );
    await setValue(`all-tags`, data);
    return data;
  } else {
    let offline_data = await getValue(`all-tags`);
    if (offline_data) {
      return offline_data;
    }
  }
};

export const getContentByTag = async (
  organ: number,
  tag: string,
  participant_id: number
) => {
  const { data } = await API.get(
    `/admin/organizations/${organ}/participants/${participant_id}/participant-contents/tags/${tag}?limit=25&page=1`
  );
  return data;
};

export const getAverageHireForParticpant = async (particpantId: number) => {
  if (navigator.onLine) {
    if (particpantId === 0) return;
    const { data } = await API.get(
      `/participants/average_hire_time?participant_id=${particpantId}`
    );
    return data;
  } else {
    let offline_data = await getValue(`averageHireForParticpant`);
    if (offline_data) {
      return offline_data;
    }
  }
};

export const getWorkerContent = async (
  uuid: string,
  organ: number,
  participantSlug: string
) => {
  if (navigator.onLine && organ !== undefined) {
    const { data } = await API.get(
      `/users/${uuid}/participant?organization=${organ}&participant=${participantSlug}`
    );
    await setValue(`workerContent`, data);
    return data;
  } else {
    let offline_data = await getValue(`workerContent`);
    if (offline_data) {
      return offline_data;
    }
  }
};

export const getJobRequisitionData = async (
  uuid: string,
  participantSlug: string
) => {
  if (navigator.onLine) {
    const { data } = await API.get(
      `/users/${uuid}/job-requisition-data?participant_id=${participantSlug}`
    );
    await setValue(`jobRequisitionData`, data);
    return data;
  } else {
    let offline_data = await getValue(`jobRequisitionData`);
    if (offline_data) {
      return offline_data;
    }
  }
};

export const setAcceptTermsAndConditions = async (uuid: string) => {
  const response = await API.put(`/users/${uuid}/accept-term`, {
    terms_accepted: true,
  });
  return response.data;
};


export const getUserOrganizationList = async (
  user_uuid: string
) => {
  const { data } = await API.get(
    `/users/organizations/list/${user_uuid}/`
  );
  return data;
};