import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)


import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';
import { getPersistedLanguage } from "./utils/LocalStorage";

const persistedLanguage = getPersistedLanguage();

const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .init({
    debug: true,
    resources,
    fallbackLng: "en",
    lng: persistedLanguage || "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;