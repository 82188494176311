import React, { FC, memo, useMemo } from "react";
import { Content_Individual } from "../../types/User";
import { useTranslation } from "react-i18next";

const ArticlePage: FC<{ content: Content_Individual }> = memo(({ content }) => {
  //   console.log("article_2", content);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const body: string = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.article.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.article[0].body;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.article.filter(
        (item: any) => item.language === currentLanguage
      )[0].body
    } else return content?.data?.article?.body;
  }, [content, currentLanguage]);

  return (
    <div className="p-4 text-black whitespace-pre-wrap bg-white rounded-b-md">
      {
        <div
        className="article-content"
          dangerouslySetInnerHTML={{
            __html: body || "",
          }}
        />
      }
    </div>
  );
});

export default ArticlePage;
