import { EnumLanguage } from "../constants";

const PATHWAYS_TOKEN = "pathways-client-token";
const PATHWAYS_ID = "pathways-id";

export const getToken = () => {
  return window.localStorage.getItem(PATHWAYS_TOKEN);
};

export const setToken = (token: string) => {
  window.localStorage.setItem(PATHWAYS_TOKEN, token);
};

export const removeToken = () => {
  window.localStorage.removeItem(PATHWAYS_TOKEN);
};

export const getPathways = () => {
  return window.localStorage.getItem(PATHWAYS_ID);
};

export const setPathways = (id: string) => {
  window.localStorage.setItem(PATHWAYS_ID, id);
};

export const removePathways = () => {
  window.localStorage.removeItem(PATHWAYS_ID);
};
export const getPathwaysSlug = () => {
  return window.localStorage.getItem("pathwaySlug");
};

export const setPathwaysSlug = (slug: string) => {
  window.localStorage.setItem("pathwaySlug", slug);
};

export const removePathwaysSlug = () => {
  window.localStorage.removeItem("pathwaySlug");
};

export const setCurrentParticipant = (participant: number) => {
  window.localStorage.setItem("currentParticipant", participant.toString());
};

export const getCurrentParticipant = () => {
  return window.localStorage.getItem("currentParticipant");
};

export const setCurrentParticipantSlug = (participantSlug: string) => {
  window.localStorage.setItem("currentParticipantSlug", participantSlug);
};

export const getCurrentParticipantSlug = () => {
  return window.localStorage.getItem("currentParticipantSlug");
};

export const getPWAInstallPrompt = () => {
  return window.localStorage.getItem("pwaInstallPrompt");
};

export const setPWAInstallPrompt = (value: "true" | "false") => {
  return window.localStorage.setItem("pwaInstallPrompt", value);
};

export const getPersistedLanguage  = (): EnumLanguage | undefined => {
  return window.localStorage.getItem("persistedLanguage") as EnumLanguage | undefined;
}

export const setPersistedLanguage = (value: EnumLanguage) => {
  return window.localStorage.setItem("persistedLanguage", value);
}