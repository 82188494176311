import {User} from "@auth0/auth0-react";

export enum ActionType {
    set_user = "SET_USER",
}

interface set_User {
    type: ActionType.set_user,
    payload: User
}

export type Actions =
    | set_User

export const setUser = (value: User): set_User => ({
    type: ActionType.set_user,
    payload: value
});