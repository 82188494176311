export const SelectorIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
      viewBox="0 96 960 960"
    >
      <path fill="currentColor" d="M480 882.92L152.31 628.306l37.307-28.153L480 825.383l290.383-225.23 37.307 28.153L480 882.92zm0-140.845L152.31 487.461 480 232.848l327.69 254.613L480 742.075z"></path>
    </svg>
  )
}