export const ConsentIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      className={`flex-shrink-0  transition duration-100 ${props.className || ""}`}
      viewBox="0 96 960 960"
    >
      <path fill="currentColor" d="M287.076 800.537L76.154 590l32.615-32.615 178.307 177.923 13.848-13.847 32.614 32.615-46.462 46.461zm185.002 0L261.54 590l32.615-32.615 177.923 177.923 379.768-379.384 32.615 32.23-412.383 412.383zM458.23 629.384l-32.614-32.615 241.229-241.23 32.615 32.615-241.23 241.23z"></path>
    </svg>
  )
};