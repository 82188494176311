export const ChecklistIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      className={`flex-shrink-0  transition duration-100 ${props.className || ""}`}
      viewBox="0 96 960 960"
    >
      <path fill="currentColor" d="M227.77 823.922L100.001 696.154l31.615-31.615 95.769 95.154L404.077 583l31.615 32.615L227.77 823.922zm0-304.615L100.001 391.539l31.615-31.614 95.769 95.153 176.692-176.692 31.615 32.615L227.77 519.307zm292.615 231.692v-45.384h339.998v45.384H520.385zm0-304.614v-45.384h339.998v45.384H520.385z"></path>
    </svg>
  )
}