import React, { FC, memo, useMemo, useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionProps, Fade, styled } from "@mui/material";
import { Content_Individual } from "../../types/User";
import { useTranslation } from "react-i18next";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const Faq: FC<{ content: Content_Individual }> = memo(({ content }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const questions = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.faq.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.faq[0].questions;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.faq.filter(
        (item: any) => item.language === currentLanguage
      )[0].questions
    } else return content?.data?.faq?.questions;
  }, [content, currentLanguage]);

  // const questions = useMemo(() => {
  //   if (content.data.template_id) {
  //     return content.data.content_template.faq[0].questions;
  //   } else return content?.data?.faq?.questions;
  // }, [content]);

  return (
    <>
      <div className="flex flex-col justify-center w-full pb-2 mb-4">
        {questions?.map((_: any, index: number) => (
          <Accordion
            key={index}
            expanded={expanded === index.toString()}
            onChange={handleChange(index.toString())}
          >
            <AccordionSummary
              expandIcon={
                expanded === index.toString() ? (
                  <FiMinusCircle className="w-5 h-5" />
                ) : (
                  <FiPlusCircle className="w-5 h-5" />
                )
              }
            >
              <p>{_.question}</p>
            </AccordionSummary>

            <AccordionDetails className="pb-1 bg-gray-100">
              <p dangerouslySetInnerHTML={{ __html: _.answer || "" }} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
});

export default Faq;
