import { AnnouncementIcon } from "./AnnouncementIcon";
import { ArticleIcon } from "./ArticleIcon";
import { ChecklistIcon } from "./ChecklistIcon";
import { ConsentIcon } from "./ConsentIcon";
import { DirectionIcon } from "./DirectionIcon";
import { FAQIcon } from "./FAQIcon";
import { SelectorIcon } from "./SelectorIcon";
import { SurveyIcon } from "./SurveyIcon";
import { SurveySequenceIcon } from "./SurveySequenceIcon";
import { VideoIcon } from "./VideoIcon";

export const ContentTemplateIcons = {
  article: <ArticleIcon />,
  video: <VideoIcon />,
  announcement: <AnnouncementIcon />,
  checklist: <ChecklistIcon />,
  selector: <SelectorIcon />,
  faq: <FAQIcon />,
  survey: <SurveyIcon />,
  direction: <DirectionIcon />,
  survey_sequence: <SurveySequenceIcon />,
  consent: <ConsentIcon />,
}