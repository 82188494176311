import React, { FC, memo, useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { Content_Individual } from "../../types/User";
import { MdCheckCircle } from "react-icons/md";
import Button from "../General/Button";
import axios from 'axios';
import { store } from '../../store/store';
import { getToken } from '../../utils/LocalStorage';
import { getSurvey } from '../../QueryHook/Contents/contents.apis';
import { MainContext } from '../../context/MainProvider';
import { useTranslation } from 'react-i18next';

const Survey: FC<{ participantSlug: string }> = (({ participantSlug }) => {

    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState<number>(0)
    // const handleChange = useCallback((item: number) => setSelected(item), []);
    const { state } = useContext(store);
    const uuid = state.user?.sub || ""
    const { mainState, setMainState } = useContext(MainContext)
    const [survey, setSurvey] = useState<any[]>()
    const [answeredNumber, setAnsweredNumber] = useState<number>(0)

    const currentParticipantOrganizationId: number = useMemo(() => {
        // const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
        // return currentParticipant?.organization_id

        if(mainState.organization_id === 0) {
            const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
            return currentParticipant?.organization_id
          } else {
            return mainState.organization_id
          }
    },[mainState.allWorkerParticipants, mainState.organization_id, participantSlug])


    useEffect(() => {
        if (mainState.surveyContentSlug) {
            getSurvey(currentParticipantOrganizationId, mainState.surveyContentSlug, participantSlug.toString()).then(response => {
                if (response) {
                    if (response.content.template_id) {
                        // console.log('survey',response?.content?.content_template)
                        const survey = response?.content?.content_template.survey.find((item:any) => item.language === i18n.language)
                        if(survey)
                        {
                            const surveyItems = survey.survey_items?.sort((a: any, b: any) => a.order - b.order)
                            setSurvey(surveyItems)
                        } else 
                        {
                        const surveyItems = response?.content?.content_template.survey[0].survey_items?.sort((a: any, b: any) => a.order - b.order)
                        setSurvey(surveyItems)
                        }
                    } else {
                        const surveyItems = response?.content?.survey?.survey_items
                        const sortedSurveyItems = surveyItems?.sort((a: any, b: any) => a.order - b.order)
                        setSurvey(sortedSurveyItems)
                    }
                    setMainState(prevState => ({ ...prevState, surveys: response }))
                    setAnsweredNumber(response?.response)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }, [mainState.surveyContentSlug, participantSlug, setMainState])



    const handleChange = (item: number) => {
        setSelected(item)
    }


    const submitForm = async () => {
        // const findId = survey?.find((_: any, ind: number) => ind === selected)?.id
        // const findId = survey?.find((_: any, ind: number) => ind === selected)
        // console.log("findId", findId)
        // if (findId) submitSurveyForm(findId)

        if (selected === 0) {
            alert("Please select an option")
            return
        }
        const baseURL = process.env.REACT_APP_API_URL
        const reqUrl = baseURL + `users/${uuid}/submit-survey-response?organization=${currentParticipantOrganizationId}&participant=${participantSlug}&content=${mainState.surveyContentSlug}`
        const response = await axios.put(reqUrl, { survey_response: selected }, { headers: { Authorization: `Bearer ${getToken()}` } })
        // to reload the new content again by backing to main content page
        setMainState(prev => {
            return  {
              ...prev,
              openContentPage: false
            }
          })
        if (response.status === 200) {
            window.location.reload()
        }
    }

    const exitSurvey = () => {
        window.location.reload()
    }





    return (
        <React.Fragment>
            <div className="pb-5 space-y-3">
                {
                    answeredNumber > 0 && survey?.map((item: any, index: number) =>
                        <button key={index}
                            disabled={answeredNumber !== item.id}
                            className={`w-full text-center p-3 rounded-lg cursor-pointer ${answeredNumber === item.id ? 'bg-green-400' : 'bg-white'} `}
                        >
                            <span className={`${'text-black'}`}>{item.label}</span>
                        </button>
                    )
                }
                {

                    answeredNumber === 0 && survey?.map((item: any, index: number) =>
                        <button key={index}
                            className={`w-full text-center p-3 rounded-lg cursor-pointer ${selected === item.id ? 'bg-orange-600' : 'bg-white'} `}
                            onClick={(e) => {
                                e.preventDefault()
                                handleChange(item.id)
                            }}>
                            <span className={`${selected === item.id ? 'text-black' : 'text-black'}`}>{item.label}</span>
                        </button>
                    )
                }
            </div>

            <Button
                    customStyles={{ backgroundColor:mainState.baseColor }}
                icon={<MdCheckCircle className="mx-1" />}
                onClick={answeredNumber > 0 ? exitSurvey : submitForm}
                label={answeredNumber > 0 ? `${t('exit')}` : `${t('completeSurvey')}`}
                extraClasses={" opacity-90"} />
        </React.Fragment>
    );
})

export default Survey;