import React from "react";

export const Loader: React.FC = () => {
    const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

    return (
        <div className="loader flex justify-center align-center h-full w-full">
            <img src={loadingImg} alt="Loading..." className="w-50 h-50" />
        </div>
    );
};