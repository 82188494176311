import { Auth0ProviderWithHistory } from "./utils/react-auth0-wrapper";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./store/store";
import { MainProvider } from "./context/MainProvider";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <MainProvider>
        <AppProvider>
          <Suspense fallback="loading">
            <App />
          </Suspense>
        </AppProvider>
      </MainProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
