import React, { FC, memo } from 'react';
import {
    MdAnnouncement,
    MdArticle,
    MdChecklistRtl,
    MdDirections,
    MdEmail,
    MdOutlineSignalCellularNull,
    MdSms
} from "react-icons/md";
import { RiSurveyFill, RiCheckDoubleFill } from "react-icons/ri";
import { AiTwotoneSound } from "react-icons/ai";
import { BsFillLayersFill, BsFillQuestionSquareFill } from "react-icons/bs";
import { FaPlay, FaUpload } from "react-icons/fa";
// import { VscGraph } from "react-icons/vsc";
import { SurveySequenceIcon } from './SurveySequence/SurverySequenceIcon';
import { VscGraph } from 'react-icons/vsc';

type typeIcon = {
    type: string | undefined
}

const TypeIcon: FC<typeIcon> = memo(({ type }) => {
    return (
        <div className="text-white">
            {(() => {
                switch (type) {
                    case 'article':
                        return <MdArticle />
                    case 'video':
                        return <FaPlay />
                    case 'checklist':
                        return <MdChecklistRtl />
                    case 'direction':
                        return <MdDirections />
                    case 'email':
                        return <MdEmail />
                    case 'sms':
                        return <MdSms />
                    case 'notification':
                        return <AiTwotoneSound />
                    case 'selector':
                        return <BsFillLayersFill />
                    case 'picture':
                        return <FaUpload />
                    case 'consent':
                        return <RiCheckDoubleFill />
                    case 'announcement':
                        return <MdAnnouncement />
                    case 'faq':
                        return <BsFillQuestionSquareFill />
                    case 'survey':
                        return <RiSurveyFill />
                    case 'survey_sequence':
                        return <SurveySequenceIcon className='w-5 h-5 text-white' />
                    default:
                        return <MdOutlineSignalCellularNull />
                }
            })()}
        </div>
    );
});

export default TypeIcon;