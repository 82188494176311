import React, { FC, memo, useContext, useEffect, useMemo } from "react";
import { Content_Individual } from "../../types/User";
import { RiCheckDoubleFill } from "react-icons/ri";
import Button from "../General/Button";
import { MdCheckCircle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getWorkerProfile } from "../../QueryHook/User/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { getToken } from "../../utils/LocalStorage";
import { MainContext } from "../../context/MainProvider";

const Consent: FC<{
  content: Content_Individual;
  submitConsentForm: (val: boolean) => void;
}> = memo(({ content, submitConsentForm }) => {

  const { mainState } = useContext(MainContext)
  const { user } = useAuth0();

  const handleSubmit = async (value: boolean) => {

    submitConsentForm(value);

  };
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const body = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.consent.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.consent[0].body;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.consent.filter(
        (item: any) => item.language === currentLanguage
      )[0].body
    } else return content?.data?.consent?.body;
  }, [content, currentLanguage]);

  return (
    <>
      <div className="relative p-4 mt-6 text-black whitespace-pre-wrap bg-gray-100 rounded-md shadow-md">
        <div className="flex items-center justify-center w-full align-center">
          <div style={{ backgroundColor: mainState.baseColor }} className="absolute left-auto right-auto p-3 text-2xl text-white rounded-full shadow-md -top-5">
            <RiCheckDoubleFill />
          </div>
        </div>
        <div
          className="pt-10 pb-4 text-sm text-center"
          dangerouslySetInnerHTML={{ __html: body || "" }}
        />
      </div>

      <div className="flex pt-8 space-x-2">
        <Button
          icon={<MdCheckCircle className="mx-1" />}
          label={`${t('yes')}`}
          customStyles={{ 'backgroundColor': mainState.baseColor }}
          extraClasses={`opacity-90`}
          onClick={() => handleSubmit(true)}
        />
        <Button
          icon={<IoMdCloseCircle className="mx-1" />}
          label={`${t('no')}`}
          customStyles={{ 'backgroundColor': mainState.baseColor }}
          extraClasses={`opacity-90`}
          onClick={() => handleSubmit(false)}
        />
      </div>
    </>
  );
});

export default Consent;
