import { useEffect, useState } from "react";
import { getValue, setValue } from "../services/offline";
import { IWorkerPathwayResponse } from "../types/Pathway";



export const useFetchPathways = (  key: string,
    request: () => Promise<IWorkerPathwayResponse>,
    enableCondition: boolean = true) => {


    const [data, setData] = useState<IWorkerPathwayResponse | null>(null);
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const fetchData = async () => {
      // console.log('daniel is feching data')
      try {
        setIsLoading(true)
        let fetchedData = await getValue(key);
        // console.log('daniel PATHWAY', fetchedData)
        if (fetchedData && fetchedData.length > 0){
          setData({
            data: fetchedData,
          });
        }

        fetchedData = await request();
        // console.log('daniel request PATHWAY', request)
        if (fetchedData && fetchedData?.data) {
          setValue(key, fetchedData);
          // console.log('PATHWAY response', fetchedData)
          setData(fetchedData as any);
        }
        setIsLoading(false)
      }catch (error) {
        // console.error("Failed to fetch data:", error);
        setIsError(true)
      }
    }




    useEffect(() => {
        if(enableCondition) {
          fetchData();
        }
      }, [enableCondition]);

    return { data, isError, isLoading }
}