export const AppRoutes = {
    STATE_FLOW: "/stateFlow",
    LINKS: "/links"
}


export const PublicRoutes = [ ]

export const MenuItems: Array<{
    id: string;
    icon: any;
    url: string;
  }> = [ ]