import axios from "axios";
import {getToken} from "../utils/LocalStorage";

// export const baseURL = 'https://cravety-server-staging-dki6q33mdq-uc.a.run.app/api/v1/'
export const baseURL = process.env.REACT_APP_API_URL

const API = axios.create({
    baseURL
});

API.interceptors.request.use(async (config) => {
    const token = getToken()
    if (config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default API