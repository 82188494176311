import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Candidate from "./components/Candidate";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/Helpers/ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import { PullToRefresh } from "react-js-pull-to-refresh";
import {
  PullDownContent,
  ReleaseContent,
  RefreshContent,
} from "react-js-pull-to-refresh";
// import { store } from "./store/store";
import { getWorkerProfile } from "./QueryHook/User/Profile";
import { setToken } from "./utils/LocalStorage";
import SplashScreen from "./components/Screens/SplashScreen";
import NotFoundScreen from "./components/Screens/NotFoundScreen";
import { MainContext } from "./context/MainProvider";
import { AppRoutes } from "./routes";
import LinksScreen from "./components/Screens/LinksScreen";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 * 5,
      retry: 3,
      retryDelay: 5000,
    },
  },
});

function App() {
  // const { state } = useContext(store);
  const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
  const {setMainState} = useContext(MainContext)

  const onRefresh = async () => {
    if (navigator.onLine) {
      window.location.reload();
    } else {
      return;
    }
  };

  useEffect(() => {
    const updateLogin = async (uuid: string) => {
      try {
        const responseToken = await getIdTokenClaims();
        if (responseToken) {
          const token = responseToken.__raw;
          setToken(token);
         const details =  await getWorkerProfile(uuid, token);
         setMainState((prev) => {
          return {
              ...prev,
              backgroundColor: details?.data?.organization_info?.BaseBackgroundColor,
              baseColor:details?.data?.organization_info?.BaseColor,
              logo:details?.data?.organization_info?.Logo,
              allWorkerParticipants:details?.data?.participants,
              organization: {
                ...prev.organization,
                name:details?.data?.organization_info?.Name,
              },
              user: {
                uuid: uuid as string
              }
          }
      })

          const themeColorElement = document.getElementById('themeColor') as HTMLElement
          themeColorElement.setAttribute('content', details?.data?.organization_info?.BaseBackgroundColor);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated && user?.sub && navigator.onLine && user !== undefined && user.sub !== undefined) {
      updateLogin(user?.sub);
    }
  // }, [getIdTokenClaims, isAuthenticated, state.user?.sub, user?.sub]);
  }, [getIdTokenClaims, isAuthenticated, user]);

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: isAuthenticated,
    authenticationPath: "/",
  };

  //   =============================================
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <PullToRefresh
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={onRefresh}
          triggerHeight={50}
          backgroundColor="white"
          startInvisible={true}
        >
          <div className="h-screen max-w-lg">
            <Routes>
              <Route path="/" element={<SplashScreen />} />
              <Route
                path={AppRoutes.STATE_FLOW}
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Home />}
                  />
                }
              />
              <Route path="/not-found" element={<NotFoundScreen />} />
              <Route path={AppRoutes.LINKS} element={<LinksScreen />} />
              <Route
                path="/candidate"
                element={
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    outlet={<Candidate />}
                  />
                }
              />
            </Routes>
          </div>
          {/* <ReactQueryDevtools initialIsOpen /> */}
        </PullToRefresh>
      </QueryClientProvider>
    </>
  );
}

export default App;
