export const SurveyIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >
      <path fill="currentColor" d="M460.308 492.154h212.614V446.77H460.308v45.384zm0 212.691h212.614v-45.384H460.308v45.384zM351.121 513.769q18.137 0 31.2-13.015 13.063-13.015 13.063-31.292 0-17.796-12.995-30.859-12.995-13.063-31.244-13.063-18.248 0-31.119 13.063-12.871 13.063-12.871 31.051 0 17.989 12.915 31.052 12.915 13.063 31.051 13.063zm0 212.691q18.137 0 31.2-13.015 13.063-13.015 13.063-31.292 0-17.796-12.995-30.859-12.995-13.063-31.244-13.063-18.248 0-31.119 13.063-12.871 13.063-12.871 31.052 0 17.988 12.915 31.051 12.915 13.063 31.051 13.063zM197.694 915.999q-23.529 0-40.611-17.082-17.082-17.082-17.082-40.611V293.694q0-23.529 17.082-40.611 17.082-17.082 40.611-17.082h564.612q23.529 0 40.611 17.082 17.082 17.082 17.082 40.611v564.612q0 23.529-17.082 40.611-17.082 17.082-40.611 17.082H197.694z"></path>
    </svg>
  )
}