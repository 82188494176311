import { t } from 'i18next';
import React from 'react';
import { AiOutlineInfoCircle } from "react-icons/ai";

const ZeroState = () => {
    return (
        <div
            className="flex flex-col items-center justify-center flex-1 h-full py-6 space-x-3">
            <AiOutlineInfoCircle className="text-7xl" />
            <div className="text-gray-400 space-y-3 max-w-[28rem]">
                <p className="text-xl text-center">{`${t('noData')}`}</p>
            </div>

        </div>
    );
};

export default ZeroState;