/* eslint-disable react/require-default-props */
import { memo } from "react";

export const CloseIcon = memo((props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      className={`flex-shrink-0 cursor-pointer text-grayColor transition duration-100 dark:text-gray-400 dark:group-hover:text-white
      ${className ? ` ${className}` : ""}`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.637 7.637"
    >
      <path
        id="icon_close_remove"
        d="M6.386,9.651,7.818,8.219,9.25,9.651l.4-.4L8.219,7.818,9.651,6.386l-.4-.4L7.818,7.417,6.386,5.986l-.4.4L7.417,7.818,5.986,9.25Zm1.432,1.986a3.7,3.7,0,0,1-1.48-.3A3.847,3.847,0,0,1,4.3,9.3a3.814,3.814,0,0,1,0-2.969,3.816,3.816,0,0,1,.821-1.212A3.914,3.914,0,0,1,6.339,4.3a3.814,3.814,0,0,1,2.969,0,3.812,3.812,0,0,1,2.029,2.029,3.814,3.814,0,0,1,0,2.969,3.914,3.914,0,0,1-.816,1.217,3.816,3.816,0,0,1-1.212.821A3.718,3.718,0,0,1,7.818,11.637Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </svg>
  );
});
