import { Fade } from "@mui/material";
import { FC, memo, useRef } from "react";

type InputProps = {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
};

export const OverlayModal: FC<InputProps> = memo(
  ({ onClose, children, isOpen }) => {
    const ref = useRef(null);

    const closeModal = (e: any) => {
      if (ref.current === e.target) {
        onClose();
      }
    };
    return (
      <Fade in={isOpen}>
        <div
          className="overlay"
          ref={ref}
          onClick={closeModal}
          style={{ minHeight: "100%" }}
        >
          {isOpen && children}
        </div>
      </Fade>
    );
  }
);
