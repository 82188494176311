import React, {
  FC,
  memo,
  useContext,
  useMemo,
} from "react";
import { FaArrowRight } from "react-icons/fa";
import TypeIcon from "../TypeIcon";
import { MainContext } from "../../context/MainProvider";
import Img from "react-cool-img";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Theme";
import ZeroState from "../ZeroState";

import { getNewContents } from "../../QueryHook/Contents/contents.apis";
import { useQuery } from "react-query";

interface NewContentProps {
  participantSlug: string;
  uuid: string;
  onhandleContentsModal: (
    id?: number,
    type?: string,
    isInteracted?: boolean,
    contentSlug?: string
  ) => void;
}

const NewContent: FC<NewContentProps> = memo((props) => {
  const { onhandleContentsModal, participantSlug, uuid } = props;


  // const { userOrganizationId } = useGetCurrentOrganization(participantSlug, uuid)

  // const { userOrganizationId } = useGetCurrentOrganization(participantSlug, uuid)

  
  
  const { t, i18n } = useTranslation();
  
  const { mainState, setMainState } = useContext(MainContext);


  const currentParticipantOrganizationId: number = useMemo(() => {
    if (mainState.organization_id === 0 && mainState?.allWorkerParticipants?.length > 0) {
      const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
      return currentParticipant?.organization_id
    } else {
      return mainState.organization_id
    }
  }, [mainState.allWorkerParticipants, mainState.organization_id, participantSlug])
    
  const { isLoading: isContentDataLoading, data: contentData } = useQuery(
    ["contentData", participantSlug, currentParticipantOrganizationId],
    () =>
      getNewContents(
        currentParticipantOrganizationId,
        uuid,
        participantSlug || ""
      ),
      {
        // !mainState.openContentPage && !!getToken() &&
        enabled:  currentParticipantOrganizationId !== 0 && currentParticipantOrganizationId !== undefined,
        refetchOnMount: 'always',
      }
  );
  // useEffect(() => {
  //   setIsLoading(true);
  //   if (!mainState.openContentPage && getToken()) {
  //     // Perform API call
  //     getNewContents(currentParticipantOrganizationId, uuid, participantSlug || "")
  //       .then((response) => {
  //         setContentData(response);
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [currentParticipantOrganizationId, mainState.openContentPage, participantSlug, uuid]);

  const CardSubTransformer = (title: string) => {
    switch (title.toLowerCase()) {
      case "survey_sequence":
        return t("provideFeedback");
      case "article":
        return t("readArticle");
      case "video":
        return t("watchVideo");
      case "faq":
        return t("accessFAQ");
      case "checklist":
        return t("accessChecklist");
      case "announcement":
        return t("readAnnouncement");
      case "survey":
        return t("provideFeedback");
      case "consent":
        return t("giveConsent");
      case "direction":
        return t("direction");
      case "selector":
        return t("selector");
      default:
        return title;
    }
  };


  // =========================== Handlers ====================================
  const openContentItem = (item: any) => {
    if (item.ContentType === "survey_sequence") {
      setMainState((prev) => {
        return {
          ...prev,
          // contentId: item.ContentID,
          contentSlug: item.ContentSlug,
          activate: true,
          surveys: null,
          openContentPage: true,
        };
      });
    } else if (item.ContentType === "survey") {
      setMainState((prev) => {
        return {
          ...prev,
          // surveyContentId: item.ContentID,
          surveyContentSlug: item.ContentSlug,
          activate: true,
          openContentPage: true,
        };
      });
    }

    setMainState((prev) => {
      return {
        ...prev,
        openContentPage: true,
      };
    });
    onhandleContentsModal(
      item.ContentID,
      item.ContentType,
      false,
      item.ContentSlug
    );
  }

  const openContentByTitle = (item: any) => {
    setMainState((prev) => {
      return {
        ...prev,
        contentSlug: item.ContentSlug,
        activate: true,
      };
    });

  }

  if (isContentDataLoading) {
    return (
      <div className="flex items-center justify-center flex-1 w-full p-2">
        <Loader isLoading={isContentDataLoading} />
      </div>
    );
  }

  return (
    <div>
      {contentData ? (
        <>
          {contentData?.data?.length === 0 || !contentData?.data ? (
            <ZeroState />
          ) : (
            contentData?.data
              ?.sort(
                (a: any, b: any) => a?.ContentPriority - b?.ContentPriority
              )
              ?.map((item: any, index: number) => {

                let title = item.ContentPreviewTitle

                let tag = ''

                if (item.ContentTemplate) {

                  // find by language:

                  // find content meta data: 
                  const contentMetaDataLanguageExists = item.ContentTemplate.content_metadata.find((metaData: any) => metaData.language === i18n.language)
                  // console.log("log", contentMetaDataLanguageExists)
                  // setting title
                  if (contentMetaDataLanguageExists) {
                    title = contentMetaDataLanguageExists.preview_title

                  } else {
                    title = item.ContentTemplate.content_metadata[0].preview_title
                  }

                  if (contentMetaDataLanguageExists) {
                    if (contentMetaDataLanguageExists.tags[0] !== "") {
                      tag = contentMetaDataLanguageExists.tags[0]
                    }
                  } else {
                    if (item.ContentTemplate.content_metadata[0].tags[0] !== "") {
                      tag = item.ContentTemplate.content_metadata[0].tags[0]
                    }
                  }
                } else {
                  if (item.ContentTag) {
                    tag = item.ContentTag[0]
                  } else {
                    tag = ""
                  }
                }


                // console.log('tag', tag)
                return (
                  <div
                    key={index}
                    className="relative mt-8 overflow-hidden rounded-sm shadow-xl text-general-text h-60"
                    onClick={() => openContentItem(item)}
                  >
                    {/*-------------------------------------------------------------------*/}
                    {/*The Content card design*/}
                    <Img
                      //loading="lazy"
                      // onError={addDefaultSrc}
                      src={item.ContentCoverImage}
                      placeholder={mainState.logo}
                      error={mainState.logo}
                      className="object-cover w-full rounded-t h-5/6 aspect-video"
                      alt="Content Image"
                    />
                    <div
                      key={index}
                      className="absolute relative h-full p-3 m-4 mt-8 bg-white rounded text-general-text bottom-36 shadow-xl-t opacity-90"
                    >
                      {item?.ContentTag && item.ContentTag.length > 0 && (
                        <span
                          style={{ backgroundColor: mainState.baseColor }}
                          className="text-white font-bold text-xs px-1 py-0.5 rounded  uppercase"
                        >
                          {/* {item.ContentTag[0]} */}
                          {tag}
                        </span>
                      )}
                      <p className="mt-1 text-base leading-5 line-clamp-4">
                        {title}
                      </p>
                      <div className="flex items-center mt-2 text-xs">
                        {item.ContentType === "survey_sequence" ? (
                          <p
                            onClick={() => openContentByTitle(item)}
                            className="inline pl-1 uppercase"
                          >
                            {t("provideFeedback")}
                          </p>
                        ) : (
                          <p className="inline pl-1 uppercase">
                            {CardSubTransformer(item.ContentType)}
                          </p>
                        )}
                        <FaArrowRight className="ml-3 capitalize" />
                      </div>
                    </div>
                    <div
                      style={{ backgroundColor: mainState.baseColor }}
                      className="absolute right-0 flex items-center justify-center w-10 h-10 mr-4 rounded-full top-4 "
                    >
                      <TypeIcon type={item.ContentType?.toLowerCase()} />
                    </div>
                  </div>
                )
              })
          )}
        </>
      ) : null}
    </div>
  );
});

export default NewContent;
