import React, { FC, useCallback, useContext, useRef } from "react";
import { FaTimes, FaHome, FaRegIdBadge } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { FiLogOut, FiHelpCircle, FiDivide } from "react-icons/fi";

import { removePathways, removeToken } from "../utils/LocalStorage";
import { store } from "../store/store";
import { useTranslation } from "react-i18next";
import SwitchLanguage from "./SwitchLanguage/SwitchLanguage";
import { MainContext } from "../context/MainProvider";
import { LinksIcon } from "../Theme";
import { AppRoutes } from "../routes";

export type SidebarProps = {
  isSidebarShow: boolean;
  setIsSidebarShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element[] | JSX.Element;
  isEmployee?: boolean;
  terms_of_service: () => void
  candidate_support_link: string
  employee_support_link: string,
  terms_of_serviceText: string
};

const Sidebar: FC<SidebarProps> = ({
  isSidebarShow,
  setIsSidebarShow,
  children,
  isEmployee,
  terms_of_service,
  candidate_support_link,
  employee_support_link,
  terms_of_serviceText
}) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { state } = useContext(store);
  const { mainState }  = useContext(MainContext)

  const navigation = useNavigate();

  const optionRef = useRef() as any;

  const handleLogout = useCallback(() => {
    removePathways();
    removeToken();
    logout({ returnTo: window.location.origin });
  }, []);

   const openLinksScreenHandler = () => navigation(AppRoutes.LINKS)

  return (
    <>
      {/*-------------------------------------------------------------------*/}
      {/*Sidebar container*/}
      <div style={{ backgroundColor:mainState.backgroundColor }} className="relative flex w-screen h-screen overflow-hidden text-white ">
        <div
          className={`w-full h-full pt-2 px-4 absolute left-0 duration-normal ${!isSidebarShow ? "-ml-56" : ""
            }`}
        >
          {/*-------------------------------------------------------------------*/}
          {/*Sidebar close and Worker name*/}
          <div
            className="flex items-center justify-center w-5 h-5 bg-white rounded-full"
            onClick={() => setIsSidebarShow(false)}
          >
            <FaTimes color="#A041DE" size={10} />
          </div>
          <p className="text-xl mt-14">
            {state.user?.name?.split(" ").slice(0, -1).join(" ")}
            <br />
            {state.user?.name?.split(" ").slice(-1).join(" ")}
          </p>

          {/*-------------------------------------------------------------------*/}
          {/*Sidebar navigation links*/}
          <ul className="p-0 mt-16">
            <li
              className="flex items-center py-2 text-sm w-fit"
              onClick={() => navigation("/stateFlow")}
            >
              <FaHome color="white" size={20} className="mr-3" />
              <span className="text-whites">{t("home")}</span>
            </li>

            {
              (isEmployee ? employee_support_link : candidate_support_link) !== "" ?
            <li className="flex items-center py-2 mt-4 text-sm text-white w-fit">
              <FiHelpCircle size={20} className="mr-3 text-white opacity-50" />
              <a
                className="text-white"
                href={
                  isEmployee
                    ? employee_support_link
                    : candidate_support_link
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('support')}
              </a>
            </li>: null
            }
            
            {
              terms_of_serviceText !== "" ? <li className="absolute flex items-center py-2 mt-4 text-sm text-white w-fit bottom-4">
              <FiDivide size={20} className="mr-3 text-white opacity-50" />
              <button
                className="text-white"
                type="button"
                onClick={() => terms_of_service()}
              >
                {t('termsOfUse')}
              </button>
            </li> : null
            }

          <li
              className="flex items-center py-2 mt-4 text-sm text-white w-fit"
              onClick={openLinksScreenHandler}
            >
              <LinksIcon className="w-6 h-6 mr-3 text-white opacity-50" />
              <span className="text-white">{t("links")}</span>
            </li>
            

            <li
              className="flex items-center py-2 mt-2 mt-4 text-sm text-white w-fit"
              onClick={handleLogout}
            >
              <FiLogOut size={20} className="mr-3 text-white opacity-50" />
              <span className="text-white">{t("logout")}</span>
            </li>
            <li className="flex items-center py-2 text-sm w-fit">
              <SwitchLanguage />
            </li>
          </ul>
        </div>

        {/*-------------------------------------------------------------------*/}
        {/*Stacked card design while the sidebar is open*/}
        <div
          ref={optionRef}
          onClick={(e) =>
            optionRef.current.contains(e?.target as Node) &&
            isSidebarShow &&
            setIsSidebarShow(false)
          }
          className={`w-full h-full absolute duration-normal right-0 flex ${!isSidebarShow ? "mr-0" : "scale-75 -mr-56"
            }`}
        >
          <div className="relative flex items-center justify-center w-full h-full">
            <div className="absolute left-0 w-full -ml-2 bg-white z-custom-1 h-layer-1 rounded-3xl opacity-95"></div>
            <div className="absolute left-0 w-full -ml-5 bg-white opacity-50 z-custom-2 h-layer-2 rounded-3xl"></div>
          </div>
          <div className="absolute w-full h-full z-custom-3">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
