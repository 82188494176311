import React, {createContext, useReducer, useEffect, useRef} from "react";

import {Actions, ActionType} from "./store.actions";
import {User} from "@auth0/auth0-react";

interface IStoreState {
    user:User
    persistenceType: string;
}

interface IAppContext {
    state: IStoreState;
    dispatch: React.Dispatch<Actions>;
}

const initialState: IStoreState = {
    user:{

    },
    persistenceType: 'localStorage'
};

const store = createContext<IAppContext>({
    state: initialState,
    dispatch: () => null
});

const {Provider} = store;

const reducer = (state: IStoreState, action: Actions) => {

    switch (action.type) {
        case ActionType.set_user:
            return {
                ...state,
                user: action.payload
            };

        default:
            return state;
    }
};

const AppProvider = ({children}: { children: JSX.Element }) => {
    const [state, dispatch] = useReducer(reducer, initializeState());

    const initialRenderGlobalState = useRef(true);
    const initialRenderPersistenceType = useRef(true);

    useEffect(() => {
        /*
         populate either sessionStorage or localStorage
         data from globalState based on persistenceType
        */
        if (initialRenderGlobalState.current) {
            initialRenderGlobalState.current = false;
            return;
        }
        const getPersistenceType = state.persistenceType;
        if (getPersistenceType === 'sessionStorage') {
            sessionStorage.setItem('globalState', JSON.stringify(state));
        } else if (getPersistenceType === 'localStorage') {
            localStorage.setItem('globalState', JSON.stringify(state));
        }
    }, [state]);

    useEffect(() => {
        /*
         purge sessionStorage or localStorage when either is selected
        */
        if (initialRenderPersistenceType.current) {
            initialRenderPersistenceType.current = false;
            return;
        }
        const getPersistenceType = state.persistenceType;
        if (getPersistenceType === 'sessionStorage') {
            localStorage.removeItem('globalState');
        } else if (getPersistenceType === 'localStorage') {
            sessionStorage.removeItem('globalState');
        }
    }, [state.persistenceType]);

    // useStoreSideEffect(state, dispatch);

    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

function initializeState() {
    /*
     the order in which the the data is compared is very important;
     first try to populate the state from Storage if not return initialState
    */

    if (typeof (Storage) !== 'undefined') {
    } else {
        throw new Error('You need to enable Storage to run this app.');
    }

    const fromLocalStorage = JSON.parse(localStorage.getItem('globalState') as string);
    // const fromSessionStorage = JSON.parse(sessionStorage.getItem('globalState') as string);
    // return fromSessionStorage || fromLocalStorage || initialState;
    return {
        ...initialState, ...fromLocalStorage, loading: false
    }
}

export {store, AppProvider};