export const FAQIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      className={`flex-shrink-0  transition duration-100 ${props.className || ""}`}
      viewBox="0 96 960 960"
    >
      <path fill="currentColor" d="M537.615 701.384q14.848 0 25.424-10.462 10.576-10.461 10.576-25.346 0-14.884-10.576-25.346-10.576-10.461-25.424-10.461-14.848 0-25.424 10.461-10.576 10.462-10.576 25.346 0 14.885 10.576 25.346 10.576 10.462 25.424 10.462zm-20.384-118.692h38.538q1.615-27.462 8.5-40.885 6.884-13.423 32.884-38.038 25.462-23.692 35.769-42.656 10.308-18.963 10.308-43.728 0-43.523-30.154-71.646-30.154-28.123-77.692-28.123-34.923 0-62.807 18.577-27.884 18.576-41.884 52.499l36.537 15.923q11.77-24.231 28.187-36.654 16.417-12.423 39.967-12.423 30.77 0 50.231 17.769 19.462 17.77 19.462 44.924 0 19.23-9.385 34.615-9.385 15.385-32.769 33.154-28.538 25.538-37.115 43.615-8.577 18.077-8.577 53.077zM278.463 825.229q-23.529 0-40.611-17.082-17.081-17.082-17.081-40.611V253.694q0-23.529 17.081-40.611 17.082-17.082 40.611-17.082h513.843q23.528 0 40.61 17.082t17.082 40.611v513.842q0 23.529-17.082 40.611-17.082 17.082-40.61 17.082H278.463zM167.694 935.997q-23.528 0-40.61-17.082-17.082-17.081-17.082-40.61V319.079h45.384v559.226q0 4.616 3.846 8.462 3.847 3.847 8.462 3.847h559.227v45.383H167.694z"></path>
    </svg>
  )
}