import { FC, useContext, useMemo } from "react";
import { MainContext } from "../../context/MainProvider";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { useQuery } from "react-query";
import { CatalogueResponse } from "../../types";
import { getCurrentParticipantSlug } from "../../utils/LocalStorage";
import { getCatalogueList } from "../../QueryHook/User";
import { Loader } from "../../Theme";
import { useTranslation } from "react-i18next";
const LinksScreen: FC = () => {

    const { mainState } = useContext(MainContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const currentParticipantOrganizationId: number = useMemo(() => {
        if (mainState.organization_id === 0) {
          const currentParticipant = mainState.allWorkerParticipants?.find((participant: any) => participant.slug === getCurrentParticipantSlug())
          return currentParticipant?.organization_id
        } else {
          return mainState.organization_id
        }
      }, [mainState.allWorkerParticipants, mainState.organization_id])
      

    const { data, isLoading } = useQuery<CatalogueResponse,Error>(["catalogue", currentParticipantOrganizationId], () => getCatalogueList(currentParticipantOrganizationId), {
        enabled: currentParticipantOrganizationId !== 0,
    })

    return (
        <div style={{ backgroundColor: mainState.backgroundColor }} className="w-full h-full">

            {/* ========================================== START Header ========================================== */}
            <div className="relative flex items-center justify-between w-full pt-3 pb-3 bg-white">
                <p className="ml-3 text-2xl font-black">
                    <img src={mainState.logo} className="w-20" alt="" />
                </p>
                <div
                    onClick={(e) => navigate(AppRoutes.STATE_FLOW)}
                    className="mr-3 bg-white rounded-full cursor-pointer"
                >
                    <MdClose className="text-2xl text-black" />
                </div>
            </div>

            {/*========================================== END Header ===============================================*/}

            <div className="flex items-center justify-center w-full p-2">
            { isLoading ? (<Loader isLoading={isLoading} />): null }
            </div>

            <div className="flex flex-col gap-4 p-4">

                <h1 className="text-2xl text-white">{t('links')}</h1>

                {
                    data && data.data.map((item) => {
                        return <a target="_blank" rel="noreferrer" href={item.url} className="flex gap-2 p-2 transition-all bg-white border-gray-300 rounded-md shadow-lg text-textDark hover:bg-gray-50" key={item.id} >
                                <img src={item.thumbnail} className="w-24 h-24 border shadow-sm rounded-xl" alt={item.title} /> 
                                <div className="flex flex-col items-center justify-center gap-2 pl-2">
                                    <h2 className="self-start text-lg font-bold">{item.title}</h2>
                                    <p className="self-start text-sm text-left">
                                    {item.description.length >= 60 ? item.description.slice(0, 60) + "..." : item.description}
                                    </p>
                                </div>
                        </a>
                    })
                }

            </div>            
        </div>
    )
}

export default LinksScreen;