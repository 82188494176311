export const NotificationsIcon = (props: { className?: string }) => {
  const { className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      className={`custom__icon ${className || ''}`}
      viewBox='0 0 5.96 7.264'
    >
      <path
        fill='currentColor'
        d='M6.98 9.764a.747.747 0 00.745-.745h-1.49a.745.745 0 00.745.745zm2.235-2.235V5.666a2.27 2.27 0 00-1.676-2.354v-.253a.559.559 0 10-1.118 0v.253a2.263 2.263 0 00-1.676 2.354v1.863L4 8.274v.373h5.96v-.373z'
        transform='translate(-4 -2.5)'
      />
    </svg>
  )
}
NotificationsIcon.defaultProps = {
  className: ''
}
