import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState, Fragment } from "react";
import { getAverageHireForParticpant, getUserOrganizationList, getWorkerCurrentPathway, getWorkerPathways, } from "../QueryHook/User/Profile";
import { State } from "../types/User";
import { FaBars, FaChevronDown } from "react-icons/fa";
import Sidebar from "./Sidebar";
import useOnClickOutside from "../hooks/UseOutsideHook";
import ContentTypeModal from "./Content/ContentTypeModal";
import BottomSheetContainer from "./BottomSheet";
import VerticalLinearStepper from "./PathwayStep";
import { ContentModal } from "../types/Content";
import {
  getCurrentParticipantSlug,
  getPathways,
  getPathwaysSlug,
  getToken,
  setCurrentParticipantSlug,
  setPWAInstallPrompt,
  setPathways,
  setPathwaysSlug,
  setToken,
} from "../utils/LocalStorage";
import { store } from "../store/store";
import { useTranslation } from "react-i18next";
import { ReactComponent as ApplicationProgressIcon } from "./../assets/application.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { MainContext } from "../context/MainProvider";
import { useFetchPathways } from "../hooks/useFetchPathways";
import { convertSecondsToDays } from "../utils/utils";
import { useFetchAverageHireForPrticipant } from "../hooks/useFetchAverageHireForPrticipant";
import PWAInitailizer from "./PWA/PWAInitializer";
import NotFoundScreen from "./Screens/NotFoundScreen";
import { Spinner } from "../Theme/Shared/AppButton/AppButtonSpinner";
import logo from './../assets/logo192_start.png'
import { useMutation, useQuery } from "react-query";
import { EnumLanguage } from "../constants";
import { IWorkerStateResponse } from "../types/WorkerState";
import { IOrganizationListResponse } from "../types/OrganizationList";
import { OverlayModal } from "../Theme/Overlay/Overlay";
import { CloseIcon, Loader } from "../Theme";
import { NotificationService, askPermission, getActionById } from "../hooks/NotificationService";
import { Popover, Transition } from "@headlessui/react";
import { IALNotification } from "../types/Notification.types";
import { Tooltip } from "flowbite-react";
import { getAllUserNotification, getAllUserUnviewedNotification, setUserNotificationsAsViewed } from "../QueryHook/Notifications/Notifications.api";
import { twMerge } from "tailwind-merge";
import moment from "moment";


type NotificationRequest = {
  data: IALNotification[]
}
const Home: FC = () => {

  NotificationService()
  const { state } = useContext(store);
  const { setMainState, mainState } = useContext(MainContext);
  // const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isProgressModalOpen, setProgressModal] = useState(false);
  const [isCompanyDropDownShown, setIsCompanyDropDownShown] = useState(false)

  askPermission()

  
  // **************************************
  // Queries

  const {
    data: getAllNotifications,
    isLoading: allLoading,
    refetch: refetchAll,
  } = useQuery<NotificationRequest, Error>('getAllNotifications', () => getAllUserNotification(50))

  const setNotificationAsReadMutation = useMutation((ids: number[]) => setUserNotificationsAsViewed(ids), {
    onSuccess: () => {
      // refetchUnread();
      refetchAll()
    }
  })

  const markAsReadHandler = (id: number) => {
    setNotificationAsReadMutation.mutate([id])
  }




  // **************************************

  const currentPathways = Number(getPathways()) || 0;


  const [val, setVal] = useState(currentPathways)
  // refactor to
  // const [val, setVal] = useState(() => {
  //   if (getCurrentParticipant()) {
  //     return Number(getCurrentParticipant());
  //   }
  //   return 0;
  // });
  const [particpantSlug, setParticipantSlug] = useState("");

  const [contents, setContents] = useState<ContentModal>({
    isOpen: false,
    type: "article",
    contentSlug: "",
    id: 0,
    isInteracted: true,
  });

  const handleContentsModal = useCallback(
    (
      id = 0,
      type: string = "",
      isInteracted: boolean = true,
      contentSlug: string = ""
    ) => {
      setContents((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
        id: Number.isInteger(id) ? id : 0,
        type,
        isInteracted,
        contentSlug,
      }));
    },
    []
  );

  // ==================================================================================================

  const [isSidebarShow, setIsSidebarShow] = useState(false);

  const [isOpen, setIsOpen] = useState(true);

  const [showOption, setShowOption] = useState(false);


  const [isShowTermOfUse, setIsShowTermOfUse] = useState<boolean>(false)


  const currentParticipantOrganizationId: number = React.useMemo(() => {
    if (mainState.organization_id === 0) {
      const currentParticipant = mainState.allWorkerParticipants?.find((participant: any) => participant.slug === particpantSlug)
      return currentParticipant?.organization_id
    } else {
      return mainState.organization_id
    }
  }, [mainState.allWorkerParticipants, mainState.organization_id, particpantSlug])


  const optionRef = useRef() as any;

  useOnClickOutside(optionRef, () => setShowOption(false));

  useEffect(() => {
    if (isSidebarShow) {
      setIsOpen(false);
      setShowNotificationBar(false)
    } else setIsOpen(true);
  }, [isSidebarShow]);
  // const [notifications, setNotifications] = useState<any[]>([]);
  const uuid = state.user?.sub || "";
  // ===========================================Notification==================================================

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (getUnreadNotification?.data) {
  //       const notificationsData = await Promise.all(
  //         getUnreadNotification.data.map(async (item: IALNotification) => {
  //           try {
  //             // Check if the action is 'al_notification'
  //             if (item.action === 'al_notification') {
  //               const result = await getActionById(
  //                 item.organization_id,
  //                 item.pathway_id,
  //                 item.state_id,
  //                 item.action_id,
  //                 uuid
  //               );

  //               const matchingLanguageItem = result.data.action_template.action_metadata.find(
  //                 (item: { language: string }) => item.language === i18n.language
  //               );

  //               let alTitle = matchingLanguageItem
  //                 ? matchingLanguageItem.title
  //                 : result.data.action_template.action_metadata[0].title;
  //               let alBody = matchingLanguageItem
  //                 ? matchingLanguageItem.al_message
  //                 : result.data.action_template.action_metadata[0].al_message;

  //               return {
  //                 id: item.ID,
  //                 alTitle,
  //                 alBody,
  //               };
  //             } else {
  //               // If action is not 'al_notification', return null to filter it out
  //               return null;
  //             }
  //           } catch (error) {
  //             console.error(`Error processing notification with ID ${item.ID}:`, error);
  //             // Return null or some falsy value to filter it out
  //             return null;
  //           }
  //         })
  //       );

  //       // Filter out notifications with errors and those not equal to 'al_notification'
  //       const successfulNotifications = notificationsData.filter(
  //         (notification) => notification !== null
  //       );

  //       console.log('successfulNotifications',successfulNotifications)

  //       setNotifications(successfulNotifications);
  //     }
  //   };

  //   fetchData();
  // }, [getUnreadNotification, i18n.language, uuid]);

  const [allNotifications, setAllNotifications] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (getAllNotifications?.data) {
        console.log('notificationsData',getAllNotifications?.data)
        const notificationsData = await Promise.all(
          getAllNotifications.data.map(async (item: IALNotification) => {
            try {
              // Check if the action is 'al_notification'
              if (item.action === 'al_notification') {
                const result = await getActionById(
                  item.organization_id,
                  item.pathway_id,
                  item.state_id,
                  item.action_id,
                  uuid
                );

                const matchingLanguageItem = result.data.action_template.action_metadata.find(
                  (item: { language: string }) => item.language === i18n.language
                );

                let alTitle = matchingLanguageItem
                  ? matchingLanguageItem.title
                  : result.data.action_template.action_metadata[0].title;
                let alBody = matchingLanguageItem
                  ? matchingLanguageItem.al_message
                  : result.data.action_template.action_metadata[0].al_message;
                return {
                  id: item.ID,
                  alTitle,
                  alBody,
                  viewed: item.viewed,
                  date: result.data.action_template.action_metadata[0].created_at,
                };
              } else {
                // If action is not 'al_notification', return null to filter it out
                return null;
              }
            } catch (error) {
              console.error(`Error processing notification with ID ${item.ID}:`, error);
              // Return null or some falsy value to filter it out
              return null;
            }
          })
        );

        // Filter out notifications with errors and those not equal to 'al_notification'
        const successfulNotifications = notificationsData.filter(
          (notification) => notification !== null
        );

        successfulNotifications.sort((a:any, b:any) => {
          if (a.viewed === b.viewed) {
            return 0;
          } else if (a.viewed && !b.viewed) {
            return 1;
          } else {
            return -1;
          }
        });
        setAllNotifications(successfulNotifications);
      }
    };

    fetchData();
  }, [getAllNotifications, i18n.language, uuid]);

  // ====================================== HANDLERS ===================================================
  const handleProgressModal = () => setProgressModal((prev) => !prev);

  const showTermOfUseModal = () => {
    setIsShowTermOfUse(true)
  }

  // ===================================================================================================

  // const handleSelect = (id: number) => {

  //     if (val === id) {
  //         setShowOption(false)
  //         return
  //     }
  //     setVal(id)
  //     // setPathways(String(id))
  //     setCurrentParticipant(id)
  //     setShowOption(false)
  //     navigate(0);
  // }

  const handleSelect = (slug: string, pathwaySlug: string) => {
    if (particpantSlug === slug) {
      setShowOption(false);
      return;
    }
    setMainState(prev => {
      return {
        ...prev,
        currentParticipantSlug: slug
      }
    })
    setParticipantSlug(slug);
    setCurrentParticipantSlug(slug);
    setPathwaysSlug(pathwaySlug);
    setShowOption(false);
  };

  //=======================================================================================

  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();



  useEffect(() => {
    const requestToAuth0 = async () => {
      try {
        const response = await getIdTokenClaims();
        await askPermission()
        if (response) {
          let token = response?.__raw as string
          setToken(token)
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated && !getToken() && window.navigator.onLine) {
      requestToAuth0();
    } else {
      // console.log('UUID is empty');
      // Handle the case when UUID is empty (e.g., user is not authenticated)
    }
  }, [getIdTokenClaims, isAuthenticated]);


  //=========================================================================================================================
  const { data: pathwayList, isError: pathwayListIsError, isLoading: pathwayListIsLoading } = useFetchPathways('pathway-list', useCallback(() => getWorkerPathways(uuid, getToken() as string), [uuid]), !!uuid && !!getToken());






  const dropDownCurrentPathway = useMemo(() => {

    let currentLanguage = i18n.language

    if (pathwayList && currentLanguage === EnumLanguage.EN) {
      // console.log('EN LOADED')
      const findPathwayByParticipantSlug = pathwayList.data.find(
        (item) => item.ParticpantSlug === particpantSlug
      )
      if (findPathwayByParticipantSlug) {
        return findPathwayByParticipantSlug.PathwayTitle
      } else {
        //
        return pathwayList.data[0].PathwayTitle
      }
    }

    if (pathwayList && currentLanguage !== EnumLanguage.EN) {
      let displayTitle: any = ''

      const mainPathway = pathwayList?.data?.find((item) => item?.ParticpantSlug === particpantSlug)
      const findPathwayByParticipantSlug = mainPathway?.PathwayLanguage?.find((item) => item.language === currentLanguage)?.display_title
      if (!findPathwayByParticipantSlug) {
        const list = pathwayList?.data.find(item => {
          if (item.PathwayLanguage) {
            return item.PathwayLanguage.find(p => p.language === currentLanguage)
          } else {
            return false
          }
        })
        const foundAPathway = list?.PathwayLanguage.find((item) => item.language === currentLanguage)
        setCurrentParticipantSlug(list?.ParticpantSlug!)
        setPathwaysSlug(list?.PathwaySlug!)
        displayTitle = foundAPathway?.display_title || (mainPathway?.PathwayTitle ?? "")
      } else {
        displayTitle = findPathwayByParticipantSlug
      }
      return displayTitle
    }
  }, [i18n.language, particpantSlug, pathwayList])

  const listOfPathwaysByLanguage: any[] = useMemo(() => {

    const i: any = []


    if (pathwayList && pathwayList?.data) {

      if (currentLanguage !== "en") {
        pathwayList.data?.forEach((item) => {
          if (item && item.PathwayLanguage.length > 0) {
            if (item.PathwayLanguage.find((item) => item.language === currentLanguage)?.display_title) {
              i.push({
                ...item,
                PathwayTitle: item.PathwayLanguage.find((item) => item.language === currentLanguage)?.display_title
              })
            } else {
              i.push({
                ...item,
                PathwayTitle: item.PathwayTitle
              })
            }

          } else {
            i.push({
              ...item,
              PathwayTitle: item.PathwayTitle
            })
          }
        })
      } else {
        pathwayList.data?.forEach((item) => {
          i.push({
            ...item,
            PathwayTitle: item.PathwayTitle
          })
        }
        )
      }

    }
    return i
  }, [currentLanguage, pathwayList])

  
  // const { isLoading, isError, data, error, isFetching } = useQuery<
  //   Worker_Pathways,
  //   Error
  // >(["pathways", uuid], () => getWorkerPathways(uuid, getToken() as string), {
  //   enabled: !!uuid && !!getToken(),
  //   onError: (error) => {
  //     // reload page
  //     console.log("error get worker pathways", error);
  //   },
  // });
  //=========================================================================================================================
  // const {
  //     isLoading,
  //     isError,
  //     data,
  //     error,
  //     isFetching
  // } = useQuery<Worker_Pathways, Error>(['pathways', uuid],
  //     () => getWorkerPathways(uuid), {
  //     onError: (error) => {
  //         // reload page
  //         console.log('error get worker pathways', error)
  //         window.location.reload()
  //     }
  // })

  //===================================================================================================
  const pathwayID = useMemo(() => {
    if (pathwayList) {
      const foundItem = pathwayList.data?.find((item) => item.ParticipantID === val)?.PathwayID || 0
      return foundItem
    }
    return 0;
  }, [pathwayList, val]);

  // const pathwayID = useMemo(
  //   () =>
  //     data?.data?.find((item) => item.ParticipantID === val)?.PathwayID || 0,
  //   [val, data]
  // );

  //===================================================================================================


  const pathwaySlug: string = useMemo(
    () => {
      if (pathwayList) {
        const foundItem = pathwayList.data?.find((item) => item.ParticpantSlug === particpantSlug)?.PathwaySlug || ""
        return foundItem
      }
      return ''
    },
    [particpantSlug, pathwayList]
  );

  //===================================================================================================
  // const participantId = useMemo(() => data?.data?.find(item => item.PathwayID === val)?.ParticipantID || 0, [val, data])
  // const getAllStates = useQuery<Worker_State, Error>(
  //   ["pathways-allStates", uuid, particpantSlug],
  //   () => getWorkerCurrentPathway(1, uuid, particpantSlug as string),
  //   {
  //     enabled: !!pathwaySlug && !!getToken(),
  //   }
  // );
  // const { data: getAllStates, isError: getAllStatesIsError, isLoading: getAllStatesIsLoading } = useFetchPathwayStates('all-states',
  //   useCallback(() => getWorkerCurrentPathway(currentParticipantOrganizationId, uuid, particpantSlug as string || getCurrentParticipantSlug() as string),
  //     [currentParticipantOrganizationId, particpantSlug, uuid]), !!uuid && !!getToken());

  const { data: getAllStates, isError: getAllStatesIsError, isLoading: getAllStatesIsLoading } = useQuery<IWorkerStateResponse, Error>(
    ["pathways-allStates", uuid, particpantSlug],
    () => getWorkerCurrentPathway(currentParticipantOrganizationId, uuid, particpantSlug as string),
    {
      enabled: !!pathwaySlug && !!getToken(),
    }
  );

  // const CurrentStateName = useMemo(
  //   () => {
  //     if (getAllStates?.data) {
  //       const allStates = getAllStates?.data
  //       const currentStateId = allStates?.current_state
  //       const currentState = allStates?.all_states?.find(item => item.id === currentStateId)

  //       if (currentState?.state_languages && currentLanguage === EnumLanguage.ES) {
  //         const esLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
  //         return esLang
  //       } else if (currentState?.state_languages && currentLanguage === EnumLanguage.FR) {
  //         const frLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
  //         return frLang
  //       } else {
  //         return currentState?.name
  //       }

  //       // if (currentState && currentLanguage === 'en') {
  //       //   return currentState.name
  //       // } else if (currentState && currentLanguage === 'es') {

  //       //   const esLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
  //       //   return esLang

  //       // } else if (currentState && currentLanguage === 'fr') {
  //       //   const frLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
  //       //   return frLang

  //       // }
  //     }
  //   }
  //   , [currentLanguage, getAllStates?.data]);
  const CurrentStateName = () => {
    if (getAllStates?.data) {
      const allStates = getAllStates?.data
      const currentStateId = allStates?.current_state
      const currentState = allStates?.all_states?.find(item => item.id === currentStateId)
      if (currentState?.state_languages && currentLanguage === EnumLanguage.ES) {
        const esLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name ?? currentState?.name
        return esLang
      } else if (currentState?.state_languages && currentLanguage === EnumLanguage.FR) {
        const frLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name ?? currentState?.name
        return frLang
      } else {
        return currentState?.name
      }

      // if (currentState && currentLanguage === 'en') {
      //   return currentState.name
      // } else if (currentState && currentLanguage === 'es') {

      //   const esLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
      //   return esLang

      // } else if (currentState && currentLanguage === 'fr') {
      //   const frLang = currentState.state_languages.find((item) => item.language === currentLanguage)?.name
      //   return frLang

      // }
    }
  }

  // const CurrentStateName = "TEST"
  // ===================================================================================================

  const stepStatePathway = useMemo(() => {
    const stepData = [] as State[];

    getAllStates?.data?.completed_states?.map((item) => {
      if (item.visible_to_worker === false) return;
      stepData.push(item);
    });

    const currentState = getAllStates?.data?.all_states?.find(
      (item) => item.id === getAllStates?.data?.current_state
    );
    if (currentState) stepData.push(currentState);

    getAllStates?.data?.all_states?.map((item) => {
      if (item.visible_to_worker === false) return;
      if (stepData.find((state) => state.id === item.id)) return;
      stepData.push(item);
    });

    return stepData;
  }, [getAllStates?.data]);

  const currentStatePosition = useMemo(() => {
    if (stepStatePathway.length > 0) {
      return stepStatePathway.findIndex(
        (item) => item.id === getAllStates?.data?.current_state
      );
    }
  }, [stepStatePathway]);

  // const PathwaysProgress = useMemo(() => {
  //   if (getAllStates?.data?.data) {
  //     const allWorkerTrueStates = getAllStates?.data?.data?.all_states?.filter(
  //       (item) => item.visible_to_worker
  //     );
  //     return (
  //       (getAllStates?.data?.data?.completed_states?.filter(
  //         (item) => item.visible_to_worker
  //       )?.length /
  //         allWorkerTrueStates?.length) *
  //         100 || 0
  //     );
  //   }
  // }, [getAllStates?.data?.data]);

  // ===================================================================================================

  // const isEmployee: boolean = useMemo(
  //   () => {
  //     if (pathwayList?.data) {
  //       return pathwayList.data?.find((item) => item.ParticipantID === val)?.IsEmployee || false
  //     } else {
  //       return false
  //     }
  //   },
  //   [pathwayList, val]
  // );
  const currentParticpantSlug = getCurrentParticipantSlug()
  const isEmployee = useMemo(
    () => pathwayList?.data?.find((item) => item.ParticpantSlug === currentParticpantSlug)?.IsEmployee,
    [currentParticpantSlug, pathwayList?.data]
  );



  // ===================================================================================================



  useEffect(() => {
    if (pathwayList) {
      const firstPathwayId = pathwayList?.data[0]?.PathwayID || 0;
      if (val !== 0) return;
      setVal(firstPathwayId);
      setPathways(String(firstPathwayId));

    }
  }, [pathwayList, val]);

  // useEffect(() => {
  //   if (data) {
  //     if (data?.data) {
  //       const firstPathwayId = data?.data[0]?.ParticipantID || 0;
  //       if (val !== 0) return;
  //       setVal(firstPathwayId);
  //       setPathways(String(firstPathwayId));
  //     }
  //   }
  // }, [data, val]);


  // =====================================================================================================



  useEffect(() => {
    if (pathwayList) {
        const currentPathwayFromLocalStorage = getPathwaysSlug()
        const currentParticipantFromLocalStorage = getCurrentParticipantSlug()
        if(currentPathwayFromLocalStorage && currentParticipantFromLocalStorage) 
        {
          // check if the pathway is exists in the pathway list
          const isPathwayExists = pathwayList?.data.find(item => item.PathwaySlug === currentPathwayFromLocalStorage)
          const isParticipantExists = pathwayList?.data.find(item => item.ParticpantSlug === currentParticipantFromLocalStorage)
          if(isPathwayExists&& isParticipantExists) {
            handleSelect(currentParticipantFromLocalStorage, currentPathwayFromLocalStorage)
          }
        } else {
          const participantSlug = pathwayList?.data[0]?.ParticpantSlug || "";
          const pathwaySlug = pathwayList?.data[0]?.PathwaySlug || "";
          if (particpantSlug === "") {
            setMainState(prev => {
              return {
                ...prev,
                currentParticipantSlug: participantSlug
              }
            })
            setParticipantSlug(participantSlug);
            setCurrentParticipantSlug(participantSlug);
            setPathwaysSlug(pathwaySlug);
          }
          if (!getPathwaysSlug()) {
            setPathwaysSlug(pathwaySlug)
          }
        }     
      askPermission()
    }
  }, [pathwayList, particpantSlug, setMainState]);

  // useEffect(() => {
  //   if (data?.data) {

  //     const participantSlug = data?.data[0]?.ParticpantSlug || "";
  //     // console.log("useEffect", data?.data[0]);
  //     const pathwaySlug = data?.data[0]?.PathwaySlug || "";
  //     if (particpantSlug !== "") return;
  //     setParticipantSlug(participantSlug);
  //     setCurrentParticipantSlug(participantSlug);
  //     setPathwaysSlug(pathwaySlug);
  //   }
  // }, [data, particpantSlug]);

  // ==============================================================================================
  const buttonRef = useRef<HTMLButtonElement>(null)
  const filterRef = useRef<HTMLDivElement>(null)

  const { data: userCompanyList, isLoading } = useQuery<IOrganizationListResponse, Error>({
    queryFn: () => getUserOrganizationList(user?.sub as string)
    , enabled: user?.sub !== undefined ? true : false && currentParticipantOrganizationId !== undefined ? true : false
  })


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetEl = event.target as Node; // Cast event.target to Node

      const filterEl = filterRef.current;
      const buttonEl = buttonRef.current;

      if (
        filterEl &&
        buttonEl &&
        !filterEl.contains(targetEl) &&
        !buttonEl.contains(targetEl)
      ) {
        setIsCompanyDropDownShown(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef, buttonRef, setIsCompanyDropDownShown]);

  // ==============================================  NOTIFICATION =======================================================

  const [showNotificationBar, setShowNotificationBar ] = useState(false)
  const notificationRef = useRef<HTMLDivElement>(null);
  const notificationButton = useRef<HTMLButtonElement>(null);


    // Close the filter by clicking outside
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const targetEl = event.target as Node; // Cast event.target to Node
  
        const _notificationRef = notificationRef.current;
        const buttonEl = notificationButton.current;
  
        if (
          _notificationRef &&
          buttonEl &&
          !_notificationRef.contains(targetEl) &&
          !buttonEl.contains(targetEl)
        ) {
          setShowNotificationBar(false);
          // alert("birone")
        }
      };
  
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [filterRef, buttonRef]);
  
    // ========================================================================


  // ===================================================================
  // const averageHireForParticpant = useQuery(
  //   ["participant", particpantSlug],
  //   () => getAverageHireForParticpant(val) ,{
  //     enabled: !!getToken(),
  //   }
  // );

  const { data: averageHireForParticpantResult } = useFetchAverageHireForPrticipant('averageHireForParticpant',
    useCallback(() => getAverageHireForParticpant(val),
      [val]), !!getToken() && !isEmployee);

  const averageHireForParticpant = useMemo(() => {
    if (averageHireForParticpantResult) {
      return averageHireForParticpantResult
    }
  }, [averageHireForParticpantResult]);



  //======================================================================================================

 


  if (getAllStatesIsLoading || pathwayListIsLoading)
    return (
      <div className="flex flex-col items-center justify-center flex-1 w-full h-full gap-5 " style={{
        backgroundColor: mainState.backgroundColor
      }}>

        <img
          src={logo}
          className="w-16 h-16 rounded-md shadow-sm"
          alt="ActionLogics"
        />
        <div className="h-32">
          <Spinner height={40} />
        </div>
      </div>
    )


  if (getAllStatesIsError || pathwayListIsError) {
    return <NotFoundScreen />
  }



  return (
    <>
      <PWAInitailizer />
      <div>

        <>
          <Sidebar
            isSidebarShow={isSidebarShow}
            setIsSidebarShow={setIsSidebarShow}
            isEmployee={isEmployee}
            terms_of_serviceText={userCompanyList?.data !== null ? userCompanyList?.data.find((item) => item.id === currentParticipantOrganizationId)?.terms_of_service || "" : ""}
            terms_of_service={showTermOfUseModal}
            employee_support_link={userCompanyList?.data !== null ? userCompanyList?.data.find((item) => item.id === currentParticipantOrganizationId)?.employee_support_link || "" : ""}
            candidate_support_link={userCompanyList?.data !== null ? userCompanyList?.data.find((item) => item.id === currentParticipantOrganizationId)?.candidate_support_link || "" : ""}
          >
            {/*-------------------------------------------------------------------*/}
            {/*Default Home container when Worker logs in*/}
            <div
              style={{
                backgroundColor: mainState.backgroundColor
              }}
              className={`w-full h-full ${isSidebarShow ? "rounded-3xl overflow-hidden" : ""
                }`}
            >
              <div className="w-full h-full text-white">
                {/*-------------------------------------------------------------------*/}
                {/*Home header*/}
                <div className="relative flex items-center justify-between w-full pt-3 pb-3 bg-white">
                  <div className="flex items-center">
                    <FaBars
                      color="#000"
                      className="absolute left-3"
                      onClick={() => setIsSidebarShow(true)}
                    />
                  </div>
                  <div>

                    <div ref={filterRef} className="flex gap-1 text-2xl font-black" onClick={() => {
                      setIsCompanyDropDownShown((prev) => !prev)
                    }}>
                      <img src={mainState.logo} className="w-20" alt="" />
                    </div>
                    <div className={`${isCompanyDropDownShown ? 'absolute' : 'hidden'} w-72 shadow-lg border-borderGray  z-50 flex flex-col items-center justify-center gap-3 p-3.5 cursor-pointer -translate-x-1/2 bg-gray-100 rounded-md left-1/2`} id="drop-list">
                      {userCompanyList?.data && userCompanyList?.data.sort((a: any, b: any) => a?.id - b?.id).map((company: any, index: number) => {
                        // {response && response.map((company: any, index:number) => {
                        return <div key={index} className="flex items-center justify-start w-full gap-2 cursor-pointer"
                          onClick={() => {
                            setMainState(prev => {
                              return {
                                ...prev,
                                backgroundColor: company.base_background_color,
                                baseColor: company.base_color,
                                logo: company.logo,
                                organization_id: company.id,
                                organization: {
                                  ...prev.organization,
                                }
                              }
                            })
                          }}>
                          <img src={company.logo} className="w-14" alt="" />
                          <p className="text-sm text-black whitespace-nowrap">{company.display_name}</p>
                        </div>
                      })}

                    </div>
                  </div>

                  <div className="flex">

                    <button
                      id="pwa-installer-prompt"
                      type="button"
                      className="flex items-center pr-3 cursor-pointer"
                      onClick={() => {
                        setMainState((prev) => {
                          return {
                            ...prev,
                            isInstalledPWA: !mainState.isInstalledPWA,
                            installAppFromHome: true
                          };
                        });
                        setPWAInstallPrompt("false");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-slate-600"
                        viewBox="0 -960 960 960"
                      >
                        <path
                          fill="currentColor"
                          d="M451.154-852.691q13.615-7.615 28.846-7.615t28.846 7.615l282.306 163q13.616 7.615 21.231 21.23 7.616 13.616 7.616 28.846v129.999q-28.923-16.769-62.192-26.269-33.269-9.5-70.269-9.5-16.769 0-32.538 2.192-15.77 2.193-30.539 5.577L774.615-625v-17.307q0-1.923-1.346-3.847-1.347-1.923-4.424-3.846l-20.537-11.692L480-505.538 211.077-661.692 191.155-650q-3.077 1.923-4.424 3.846-1.346 1.924-1.346 3.847v17.922l271.923 157.154v54.23q-17.846 29.923-27.962 64-10.115 34.077-10.115 71.923 0 50.231 17.23 94 17.231 43.769 47.846 78.845-8.538 2.847-17.269 1.731-8.731-1.115-16.653-5.192L168.848-270.309q-13.616-7.615-21.231-21.23-7.616-13.616-7.616-28.846v-319.23q0-15.23 7.616-28.846 7.615-13.615 21.231-21.23l282.306-163zm243.461 682.844l105.692-105.692-19.846-19.846-71.615 72v-161.384h-28.077v161.384l-72-72-19.846 19.846 105.692 105.692zm-7.308 65.23q-71.076 0-121.883-50.307-50.808-50.308-50.808-121.769 0-71.692 50.808-122.499 50.807-50.807 121.883-50.807 71.077 0 121.884 50.807 50.808 50.807 50.808 122.499 0 71.461-50.808 121.769-50.807 50.307-121.884 50.307z"
                        ></path>
                      </svg>
                    </button>
                    <button type="button" 
                    ref={notificationButton}
                    onClick={() => setShowNotificationBar(prev => !prev)}
                    className="relative inline-flex items-center pr-4 border-none outline-none group focus-within:border-none focus:border-none"
                    >
                        <svg
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              className={twMerge("w-5 h-5 text-slate-600", showNotificationBar ? 'text-blue-500': '')}
                              viewBox='0 0 5.96 7.264'
                            >
                              <path
                                fill='currentColor'
                                d='M6.98 9.764a.747.747 0 00.745-.745h-1.49a.745.745 0 00.745.745zm2.235-2.235V5.666a2.27 2.27 0 00-1.676-2.354v-.253a.559.559 0 10-1.118 0v.253a2.263 2.263 0 00-1.676 2.354v1.863L4 8.274v.373h5.96v-.373z'
                                transform='translate(-4 -2.5)'
                              />
                            </svg>
                            {/* {
                              allNotifications && allNotifications.length > 0 ?
                                <div className="mx-2 h-2 w-2 rounded-full bg-red-600 border-[2px] border-red-700 absolute top-0 right-[6px]" /> : null
                            } */}
                    </button>
                          {/* <div className={twMerge("p-2 bg-white", 
                          showNotificationBar! ? '' :'hidden')} ref={notificationRef}>
                              <h1 className="text-lg text-black">salam</h1>
                          </div> */}
                          <div
                          className={twMerge("absolute z-50 max-w-sm px-4 mt-3 transform -translate-x-1/2 w-[25rem] top-8 -right-48 sm:px-0 lg:max-w-3xl",
                          showNotificationBar! ? '' :'hidden')}
                          ref={notificationRef} >
                            {/* overflow-hidden */}
                                <div className="min-h-full overflow-y-scroll rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                         
                            <div className="relative grid gap-8 p-5 overflow-y-scroll bg-white h-2/6 max-h-[80vh]">
                            <>
                                      {allLoading ? <div className="flex items-center justify-center p-2 -m-3">
                                        <Loader isLoading={allLoading} size={25} />
                                      </div> : null}

                                      {allNotifications.map((notification) => (
                                        <div
                                        onClick={() => {
                                          if(!notification.viewed) { 
                                            markAsReadHandler(notification?.id)
                                          }
                                        }}
                                          key={notification?.id}
                                          className={
                                            twMerge("flex items-center shadow-md p-1 -m-3 transition duration-150 ease-in-out rounded-lg group hover:cursor-pointer hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50",
                                            notification?.viewed ? 'bg-gray-200' : 'bg-blue-300')
                                          }
                                        >
                                         
                                          <div className="w-full ml-4">

                           
                                              <div className="flex">
                                                <div className="flex flex-col">
                                                <div className="flex flex-col gap-1">

                                          
<p className={
  twMerge("text-sm font-bold text-gray-900 pt-2 pb-1",
  notification?.viewed ? '' : 'font-bold')
}>{notification?.alTitle}</p>

</div>
<p className="text-xs text-gray-900">{notification?.alBody}</p>
                                                </div>
                                                <div className="flex flex-col items-center justify-end mb-2">
                                                {
                                                !notification.viewed && <div className="h-3 w-3 rounded-full mr-1 bg-blue-600 border-[2px] border-blue-700" />
                                                 }
                                                </div>
                                              </div>

                                            <p className={twMerge("w-full pt-1 pb-2 text-xs text-gray-900", 
                                            !notification?.viewed ? 'text-blue-700': ''
                                            )}>{moment(notification.date).fromNow()}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                </div>
                          </div>
                          </div>
                         
                  </div>
                </div>

                {/*-------------------------------------------------------------------*/}
                {/*Section displaying the Worker's current Pathway and State*/}
                <div className="px-4 my-5 ">
                  <p className="text-lg">
                    {t("hi")}{" "}
                    {state.user?.name?.split(" ").slice(0, -1).join(" ")},{" "}
                    {t("youreviewing")}:
                  </p>

                  {/*-------------------------------------------------------------------*/}
                  {/*The Worker's current Pathway*/}
                  <div ref={optionRef}>
                    <div
                      className="flex items-center text-base w-fit"
                      onClick={() => setShowOption((prev) => !prev)}
                    >
                      <p className="line-clamp-1 opacity-80">{dropDownCurrentPathway}</p>
                      <FaChevronDown className="ml-3 mt-0.5 inline" />
                    </div>

                    {showOption && (
                      <div className="absolute z-20 w-11/12 p-2 overflow-auto text-xl text-gray-600 text-opacity-50 bg-white bg-opacity-100 border rounded-md shadow top-2/5 max-h-min">
                        <ul className="space-y-1">
                          {

                            listOfPathwaysByLanguage && listOfPathwaysByLanguage.map((item: any, index: number) => (
                              <li
                                onClick={() => handleSelect(item.ParticpantSlug, item.PathwaySlug)}
                                className={`${item.ParticpantSlug === particpantSlug &&
                                  "text-organization-primary"
                                  }`}
                                key={item.PathwaySlug}
                              >
                                {item.PathwayTitle}

                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/*-------------------------------------------------------------------*/}
                  {/*The Worker's current State*/}
                  <div className="w-full border-b-2 border-green-50 h-0.5 opacity-20 py-3"></div>
                  <div className="flex flex-col my-5 ">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-md">{t("CurrentStatus")}:</p>
                        <p className="flex items-center text-white text-md">
                          {CurrentStateName()}
                        </p>
                      </div>
                      {!isEmployee && (
                        <div
                          className="hover:cursor-pointer"
                          onClick={handleProgressModal}
                        >
                          <ApplicationProgressIcon />
                        </div>
                      )}
                    </div>

                    <div>
                      {/*<div className="my-3"></div>*/}
                      {
                        !isEmployee && (
                          <p className='mt-2 text-sm italic'>
                            {/*{t('averageHireTime')}:{" "}*/}
                            {
                              averageHireForParticpant?.data?.average_hire_time && averageHireForParticpant?.data?.average_hire_time > 0 ? <>{
                                t('averageHireTime') + ' ' + t('averageHireTime') + ': ' + convertSecondsToDays(averageHireForParticpant.data.average_hire_time) +
                                `  ${t('days')}`
                              }</> : null
                            }
                          </p>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/*-------------------------------------------------------------------*/}
                {/*Container for the list of Content*/}
                  {
                    !showNotificationBar && ( 
                      <BottomSheetContainer
                    isContentsModalOpen={contents.isOpen}
                    isSheetOpen={isOpen && !isProgressModalOpen && !showOption}
                    handleContentsModal={handleContentsModal}
                    participantId={val}
                    pathwayID={pathwayID}
                    pathwaySlug={pathwaySlug}
                    participantSlug={particpantSlug}
                  />
                    )
                  }
              </div>
            </div>
          </Sidebar>
        </>
        <ContentTypeModal
          id={contents.id}
          type={contents.type}
          isOpen={contents.isOpen}
          isInteracted={contents.isInteracted}
          participantSlug={particpantSlug as string}
          pathwaySlug={pathwaySlug}
          contentSlug={contents.contentSlug}
          onClose={handleContentsModal}
          participantId={val}
          pathwayID={pathwayID}
        />

        {isProgressModalOpen && getAllStates?.data?.all_states ? (
          <VerticalLinearStepper
            uuid={uuid}
            participantSlug={particpantSlug as string}
            steps={stepStatePathway}
            currentPosition={currentStatePosition}
            handleModal={handleProgressModal}
          />
        ) : null}
        <OverlayModal isOpen={isShowTermOfUse} onClose={() => setIsShowTermOfUse(false)}>
          <div className="flex flex-col items-center w-3/5 px-4 py-5 bg-white rounded-md terms-condition">
            <div className="flex justify-end w-full">
              <button onClick={() => setIsShowTermOfUse(false)}><CloseIcon className="w-4 h-4 text-gray-400" /></button>
            </div>
            <div className="flex flex-col items-center justify-center">
              <img src={mainState.logo} alt="" className="mb-1 w-28" />
              <h3 className="font-bold text-[#eb004e]">{t('termsOfUse')}</h3>
              <div className="flex flex-col mt-4">
                <div className="my-2 text-xs text-[#303539]" dangerouslySetInnerHTML={
                  { __html: userCompanyList?.data !== null ? userCompanyList?.data.find((item) => item.id === currentParticipantOrganizationId)?.terms_of_service || `${t('noData')}` : `${t('noData')}` }
                } />
              </div>
            </div>
          </div>
        </OverlayModal>
      </div>
    </>

  )
};

Home.displayName = "Home Screen";

export default Home;


