import { useEffect, useState } from "react";
import { getValue, setValue } from "../services/offline";
import { IAverageHireParticipantResponse } from "../types/AverageHireParticipant";



export const useFetchAverageHireForPrticipant = (  key: string,
    request: () => Promise<IAverageHireParticipantResponse>,
    enableCondition: boolean = true) => {


    const [data, setData] = useState<IAverageHireParticipantResponse | null>(null);

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
          try {
            let fetchedData = null;
    
            const isOffline = !navigator.onLine;
    
            if (isOffline) {
              fetchedData = await getValue(key);
              // console.log("fetched average hire (offline)", fetchedData);

              // if (isMounted) {
              //   setData(fetchedData|| []);
              // }
            } else {
              const responseData = await request();
              fetchedData = responseData;
              // console.log("fetched average hire (online)", fetchedData);
    
              if (fetchedData) {
                setValue(key, fetchedData.data);
                
                if (isMounted) {
                  setData(fetchedData || []);
                }
              }
            }
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        };
    
        if (enableCondition) {
          fetchData();
        }
    
        return () => {
          isMounted = false;
        };
      }, [enableCondition, key, request]);

    return { data }
}