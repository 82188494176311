/* eslint-disable */
import React, { FC, useContext, useEffect, useMemo, useState, useTransition } from "react";
import { MdClose } from "react-icons/md";
import { Content_Individual } from "../../types/User";
// import fedexLogo from "../../assets/logo-dark.png";
import { useSearchParams } from "react-router-dom";
import { MainContext } from "../../context/MainProvider";
import SurveySequenceHeader from "../SurveySequence/SurveySequenceHeader";
import { blobToImageUrl, downloadAsBlob, getValue } from "../../services/offline";
import { getCurrentParticipantSlug } from "../../utils/LocalStorage";
import { useTranslation } from "react-i18next";

interface Props {
  children?: JSX.Element[] | JSX.Element;
  handleModal: (e: any) => void;
  id: number;
  type: string;
  content: Content_Individual;
}

const ContentTypeContainer: FC<Props> = ({
  children,
  handleModal,
  id,
  type,
  content,
}) => {


  const { t, i18n } = useTranslation();


  const { mainState, setMainState } = useContext(MainContext);

  const { step } = mainState;

  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get("val");

  const titleForSeq = useMemo(
    () => content?.data?.survey_sequence?.surveys[Number(param)],
    [param]
  );



  // const titleForSeq = 'test'§

  const addDefaultSrc = (ev: any) => (ev.target.src = mainState.logo);
  // const addDefaultSrc = (ev: any) =>
  // (ev.target.src =
  //   "https://www.fedex.com/content/dam/fedex/us-united-states/about-us/images/2020/Q4/EU_EN_2020_1_web_jpg_NA_connect_possibilities_home_marketing_673415817_smaller.jpg");


  const title =
    content?.data?.video?.title ||
    content?.data?.article?.title ||
    content?.data?.checklist?.title ||
    content?.data?.direction?.title ||
    content?.data?.selector?.title ||
    content?.data?.picture?.title ||
    content?.data?.announcement?.title ||
    content?.data?.faq?.title ||
    content?.data?.survey?.title ||
    content?.data?.consent?.title ||
    content?.data?.survey_sequence?.title ||
    "";
  const description =
    content?.data?.video?.description ||
    content?.data?.article?.description ||
    content?.data?.checklist?.description ||
    content?.data?.direction?.description ||
    content?.data?.selector?.description ||
    content?.data?.picture?.description ||
    content?.data?.announcement?.description ||
    content?.data?.faq?.description ||
    content?.data?.survey?.description ||
    content?.data?.consent?.title ||
    "";

  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [currentDescription, setCurrentDescription] = useState<string>("");
  const [currentTag, setCurrentTag] = useState<string>("");

  useEffect(() => {
    if (content.data === undefined) {
      setCurrentTitle('');
      return;
    } else if (content.data.template_id && content.data.content_template) {
      const currentType = content.data.content_type;
      const currentLanguage = i18n.language; 
      // const title = content.data.content_template[currentType].find(
      //   (item: any) => item.language === currentLanguage
      // )?.title;

      let title = ''
      const findTitleByLanguage =  content.data.content_template[currentType].find(
          (item: any) => item.language === currentLanguage
      )

      if(findTitleByLanguage) { 
        title = findTitleByLanguage.title
      }
      else {
        title = content.data.content_template[currentType][0].title
      }
      setCurrentTitle(title);
      // setCurrentTitle(content.data.content_template[currentType][0].title);
    } else {
      setCurrentTitle(title);
    }
  }, [content, title]);

  useEffect(() => {
    if (content.data === undefined) {
      setCurrentDescription('');
      return;
    } else if (content.data.template_id && content.data.content_template) {
      const currentType = content.data.content_type;
      // find the description by current language
      const currentLanguage = i18n.language; 
    
      let description = ''
      const findDescriptionByLanguage =  content.data.content_template[currentType].find(
        (item: any) => item.language === currentLanguage
      )

      if(findDescriptionByLanguage) { 
        description = findDescriptionByLanguage.description
      }else {
        description = content.data.content_template[currentType][0].description
      }

      setCurrentDescription(description);
    } else {
      setCurrentDescription(description);
    }
  }, [content, description]);

  useEffect(() => {

    if (content.data === undefined) {
      setCurrentTag('');
      return;
    } else if (content.data.template_id && content.data.content_template) {
      const currentLanguage = i18n.language;  
     let tag = ''
     const findTagByLanguage = content.data.content_template.content_metadata.find((item:any) => {
        return item.language === currentLanguage
      })

      if(findTagByLanguage) {
        tag = findTagByLanguage.tags[0]
      } else {
        tag = content.data.content_template.content_metadata[0].tags[0]
      }
      setCurrentTag(tag);
      
    } else if (content?.data?.tags?.length) {
      // content?.data?.tags?.length ? content?.data?.tags[0] : ""
      setCurrentTag(content?.data?.tags?.[0] || "");
    }
  }, [content]);

  // =========================

  // console.log('test',mainState.surveySequence)

  // @ts-ignore
  const slug = content?.data?.slug

  // load cover image from cache
  const coverImage = useMemo(() => {
    if (navigator.onLine) {
      return content.data?.template_id ? content?.data?.content_template?.content_metadata[0]?.cover_image : content?.data?.cover_image
    } else {
      let url = ''
      getValue('new-content-' + getCurrentParticipantSlug()).then(newContentList => {
        const found = newContentList.find((item: any) => {
          if (item.ContentSlug === slug) {
            // console.log('found-seen', item)
            return item
          }
        })
        if (found) {
          const createTempURL = URL.createObjectURL(found.localBlob as Blob);
          url = createTempURL
        }
      })

      getValue('seen-content-' + getCurrentParticipantSlug()).then(async (seenContentList) => {
        const found = seenContentList.find((item: any) => {
          if (item.ContentSlug === slug) {
            // console.log('found-seen', item)
            return item
          }
        })
        if (found) {
          const createTempURL = URL.createObjectURL(found.localBlob as Blob);
          url = createTempURL
        }
      })
    }
  }, [])




  // console.log('ss',mainState?.surveySequence?.content.content_template.survey_sequence.title)
  // console.log('ss',Object.entries(mainState?.surveySequence?.content?.content_template?.survey_sequence)
  // .map(([key, value]) => {
  //   if(key !== "surveys") {
  //     return value
  //   }
  // }).filter(i => i !== undefined))
  // =========================


  const surveySequenceTitle = useMemo(() => {

  },[])

  function loadSurveyTitle(): string {

    let title = ''
    // return mainState.surveys.content.content_template.survey.find((item: any) => {
    //   return item.language === i18n.language;
    // }).title || '';
    const findTitleOfSurveyById = mainState.surveys.content.content_template.survey.find((item: any) => {
        return item.language === i18n.language;
      })
      if(findTitleOfSurveyById) { 
        title = findTitleOfSurveyById.title
      }
      else {
        title = mainState.surveys.content.content_template.survey[0].title
      }

    return title
  }

  function loadSurveyTag(): string {

    let tag = ''
    const findTagOfSurveyById = mainState.surveys.content.content_template.content_metadata.find((item: any) => {
        return item.language === i18n.language;
      })
      if(findTagOfSurveyById) { 
        tag = findTagOfSurveyById.tags[0] || ''
      }
      else {
        tag = mainState.surveys.content.content_template.content_metadata[0].tags[0] || ''
      }
    return tag
  }



  function loadSurveyDescription(): string {
    let description = ''
    const findDescriptionOfSurvey = mainState.surveys.content.content_template.survey.find((item: any) => {
      return item.language === i18n.language;
    })
    if(findDescriptionOfSurvey) { 
      description = findDescriptionOfSurvey.description
    } else {
      description = mainState.surveys.content.content_template.survey[0].description
    }
    return description
  }



  function loadSurveySequenceTitle(): string {
    let title = '';

    // @ts-ignore
    const findTitleByLanguage = Object.entries(mainState?.surveySequence?.content?.content_template?.survey_sequence)
      .map(([key, value]) => {
        if (key !== 'surveys') {
          return value;
        }
      })
      .filter((i) => i !== undefined)
      .find((item: any) => {
        return item.language === i18n.language;
      });

    if (findTitleByLanguage) {
      // @ts-ignore
      title = findTitleByLanguage.title;
    } else {
      title = mainState?.surveySequence?.content?.content_template?.survey_sequence[0].title;
    }
    return title;
  }


 function loadSurveySequenceTag(): string {

  let tag = ''

  const findTagByLanguage = mainState.surveySequence?.content.content_template.content_metadata.find((item: any) => {
    return item.language === i18n.language;
  })

  if(findTagByLanguage) {
    tag = findTagByLanguage?.tags[0] || ''
  } else {
    tag = mainState.surveySequence?.content?.content_template?.content_metadata[0].tags[0] || ''
  }
  // return mainState.surveySequence?.content.content_template.content_metadata.find((item: any) => {
  //   return item.language === i18n.language;
  // }).tags[0];


  return tag
}


  

  return (
    <div     style={{ backgroundColor:mainState.backgroundColor }} className={`w-full`}>
      <div className="w-full h-full text-white">
        {/* START Header bar with logo and settings icon*/}
        <div className="relative flex items-center justify-between w-full pt-3 pb-3 bg-white">
          <p className="ml-3 text-2xl font-black">
            <img src={mainState.logo} className="w-20" alt="" />
          </p>
          <div
            onClick={(e) => {
              setMainState((prev) => {
                return { ...prev, step: 0, activate: false, openContentPage: false };
              });
              handleModal(e);
            }}
            className="mr-3 bg-white rounded-full cursor-pointer"
          >
            <MdClose className="text-2xl text-black" />
          </div>
        </div>

        {/*END Header*/}

        {mainState.activate && mainState.surveys === null ? (
          <React.Fragment>
            <div className="flex flex-col items-center px-4 pb-10 pt-14 custom-scroll">
              {step === 0 && (
                <React.Fragment>
                  <SurveySequenceHeader
                    tagTitle={ mainState.surveySequence?.content.tags ? mainState.surveySequence?.content.tags[0]: loadSurveySequenceTag() || ''}
                    title={
                      mainState.surveySequence?.content.template_id ?  loadSurveySequenceTitle() : mainState.surveySequence?.content.survey_sequence.title || ''}
                    // imgUrl={mainState.surveySequence?.content.cover_image as string}
                    // mainState.surveySequence?.content.survey_sequence?[0].title 
                    onErrorLoadImg={addDefaultSrc}
                  />
                </React.Fragment>
              )}

              {children}
            </div>
          </React.Fragment>
        ) : mainState.activate && mainState.surveys !== null ? (
          <React.Fragment>
            <div className="flex flex-col items-center px-4 pb-10 pt-14 custom-scroll">
              <SurveySequenceHeader
                tagTitle={loadSurveyTag()}
                title={mainState.surveys.content?.survey?.title === '' ? '' : loadSurveyTitle()}
                description={mainState.surveys.content?.survey?.description === '' ? '' : loadSurveyDescription()}
              />
              {children}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/*-------------------------------------------------------------------*/}
            {/*Content container holding the details of the selected Content type*/}
            <div className="flex flex-col items-center px-4 pb-10 pt-14 custom-scroll">
              <span style={{ backgroundColor: mainState.baseColor }} className="text-white text-xs px-1 py-0.5 my-2 rounded uppercase font-bold">
                {/* content?.data?.tags?.length ? content?.data?.tags[0] : "" */}
                {/* {content.data.template_id ? content.data.content_template.content_metadata[0].tags[0]} */}
                {currentTag}
              </span>
              <span className="pb-5 text-xl text-center">
                {param === null ? currentTitle : titleForSeq?.title}
              </span>

              <span className="pb-2 text-sm text-center">
                {param === null ? currentDescription : titleForSeq?.description}
              </span>

              <hr className="border-[1px] border-gray-200 w-full my-5 opacity-10" />

              {content?.data?.content_type !== "announcement" &&
                content?.data?.content_type !== "selector" &&
                content?.data?.content_type !== "survey" &&
                content?.data?.content_type !== "video" &&
                content?.data?.content_type !== "direction" &&
                content?.data?.content_type !== "consent" &&
                content?.data?.content_type !== "survey_sequence"
                && (
                  <img
                    className="w-full shadow-md aspect-video rounded-t-md"
                    // src={content.data.template_id ? content?.data?.content_template?.content_metadata[0]?.cover_image : content?.data?.cover_image }
                    src={coverImage}
                    onError={addDefaultSrc}
                    alt=""
                  />
                )}

              {children}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );




};

export default ContentTypeContainer;
