import { ChangeEvent, FC, useState } from "react";
import i18n from "../../i18n";
import { getPersistedLanguage, setPersistedLanguage } from "../../utils/LocalStorage";
import { EnumLanguage } from "../../constants";


const SwitchLanguage: FC = () => {
  const [state, setState] = useState<EnumLanguage>(() => getPersistedLanguage() || EnumLanguage.EN)

  const switchLang = (event: ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value as EnumLanguage
    i18n.changeLanguage(lang)
    setState(prev => {
      return prev = lang
    })
    setPersistedLanguage(lang)
  }

  return (
    <div>
      <select name="switch" id="switch" value={state} onChange={switchLang} className="block w-full px-2 py-1 mb-1 text-base font-normal text-gray-700 transition ease-in-out bg-white bg-no-repeat border border-gray-300 border-solid rounded appearance-none form-select bg-clip-padding focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" >
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
      </select>
    </div>
  )

}


export default SwitchLanguage;