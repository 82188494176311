import { CSSProperties, FC } from 'react'

type ButtonProps = {
    label: string;
    icon?: JSX.Element | null
    disabled?: boolean;
    extraClasses?: string;
    bgColor?: string;
    theme?: string;
    onClick?: () => void;
    customStyles?:CSSProperties
}

const Button: FC<ButtonProps> = ({ label, onClick, disabled, icon, extraClasses, customStyles }) => {

    return (
        <button
            style={customStyles}
            className={`btn ` + extraClasses}
            disabled={disabled}
            onClick={onClick}
        >
            {icon && icon}
            {label}
        </button>
    )
}

export default Button