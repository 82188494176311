import * as React from 'react';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import fedexLogo from "../assets/logo-dark.png";
import { MdClose } from "react-icons/md";
import { FC } from "react";
import { State } from "../types/User";
// import { StepConnector } from "@mui/material";
import { StepIconProps } from '@mui/material/StepIcon';
import { VscKebabVertical } from "react-icons/vsc";
// import { withStyles } from "@mui/styles";
import { useQuery } from 'react-query';
import { getJobRequisitionData, getWorkerContent } from '../QueryHook/User/Profile';
import { IoMdInformationCircle } from 'react-icons/io'
import { MainContext } from '../context/MainProvider';
import { useTranslation } from 'react-i18next';

type prop = {
    handleModal: () => void
    steps?: State[],
    currentPosition?: number
    uuid: string
    participantSlug: string
}


const VerticalLinearStepper: FC<prop> = ({ handleModal, steps, currentPosition, uuid, participantSlug }) => {

    const { t } = useTranslation();

    const { mainState } = React.useContext(MainContext)

    const currentParticipantOrganizationId: number = React.useMemo(() => {
        // const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
        // return currentParticipant?.organization_id


        if (mainState.organization_id === 0) {
            const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
            return currentParticipant?.organization_id
        } else {
            return mainState.organization_id
        }
    }, [mainState.allWorkerParticipants, mainState.organization_id, participantSlug])

    const { data, isLoading } = useQuery(['worker-data-fields'], () => getWorkerContent(uuid, currentParticipantOrganizationId, participantSlug))
    console.log('worker-data-fields', data)
    const { data: jobData, isLoading: jobIsLoading } = useQuery(['job-requisition-data'], () => getJobRequisitionData(uuid, participantSlug))



    // const wocFormat = (input: string) => {
    //     // WOTC status: if blank > No info available; 'PASS' or 'RECRUITING_ASSESSMENT_STATUS_COMPLETE' > Completed;
    //     // Pending > In progress; Anything else: No info available participant.assesment_test_status_reference 
    //     if (input === 'PASS' || input === 'RECRUITING_ASSESSMENT_STATUS_COMPLETE') return `${t('completed')}`
    //     else if (input === 'Pending') return `${t('inProgress')}`
    //     else return `${t('NoInformationAvailable')}`
    // }


    const wocFormat = (value: string) => {

        if (!value) {
            return `${t('NoInformationAvailable')}`
        }
        const input = value.toLowerCase(); // Convert input to lowercase
        if (input === 'pending') {
            return `${t('inProgress')}`;
        } else if (input === 'pass' || input === 'recruiting_assessment_status_complete') {
            return `${t('completed')}`;
        } else {
            return `${t('NoInformationAvailable')}`
        }
    };


    const backgroundFormat = (value: string) => {
        if (!value) {
            return `${t('NoInformationAvailable')}`
        }
        const input = value.toLowerCase();

        if (input === 'completed' || input === 'passed') return `${t('completed')}`
        else return `${t('pending')}`
    }

    const drugFormat = (value: string) => {
        if (!value) {
            return `${t('NoInformationAvailable')}`
        }
        const input = value.toLowerCase();
        // Drug Screen Status: NA, Blank: No info available; Fail, Pass, Review: Completed   
        // FAIL, PASS, REVIEW       participant.drugscreen_status
        if (input === 'pass' || input === 'fail' || input === 'review') return `${t('completed')}`
        // else if (input === 'na' || input === '') return `${t('NoInformationAvailable')}`
        else return `${t('NoInformationAvailable')}`
    }


    const [isActive, setIsActive] = React.useState<boolean>(false)
    const showModal = () => {
        setIsActive(!isActive)
    }

    if (isLoading && jobIsLoading) return <div>{t('loading')}...</div>

    return (
        <div className="overflow-scroll overlay">
            <div style={{ backgroundColor: mainState.backgroundColor }} className="w-full h-screen overflow-auto text-gray-200 ">
                <div className="relative flex items-center justify-between w-full pt-3 pb-3 bg-white">
                    <p className="ml-3 text-2xl font-black">
                        <img src={mainState.logo} className="w-20" alt='' />
                    </p>
                    <div onClick={handleModal} className="mr-3 bg-white rounded-full cursor-pointer">
                        <MdClose className="text-2xl text-black" />
                    </div>
                </div>

                <div className='p-5'>
                    <div className="mb-5">
                        <p className="text-sm text-white">{t('YouAppliedFor')}:</p>
                        <p className='mt-1 text-2xl text-white'>{data?.data?.pathway_title}</p>
                        {/* <p className="text-sm text-white">Hi {data?.data?.full_name}, you're viewing the job details for</p> */}
                    </div>

                    <div className="w-full border-b-2 border-green-50 h-0.5 opacity-10 my-3" />

                    <div className="mt-5">

                        <div className='mb-2'>
                            <p className="flex text-sm font-thin"><span className='mr-1'>{t('currentRequisitionID')}</span> <IoMdInformationCircle onClick={showModal} className='w-5 h-5 hover:cursor-pointer' /></p>
                            <p className='text-lg font-extrabold text-white'>{data?.data?.job_requisition_id_candidate || `${t('NoInformationAvailable')}`}
                            </p>
                        </div>

                        {
                            isActive ? <div className='p-2 my-2 bg-white rounded shadow-sm opacity-80'>
                                <p className='text-sm text-left text-black'>
                                    {t('currentRequisitionIDModal')}
                                </p>
                            </div> : null
                        }

                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('jobTitle')}</p>
                            {/* jobData?.data?.posting_title_job === '' ? jobData?.data?.posting_title_evergreen : `${t('NoInformationAvailable')}` */}
                            <p className='text-lg font-extrabold text-white'>{jobData?.data?.posting_title_job === '' || jobData?.data?.posting_title_evergreen} </p>
                        </div>

                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('primaryLocation')}</p>
                            <p className='text-lg font-extrabold text-white'>{data?.data?.primary_location_address.slice(4) || `${t('NoInformationAvailable')}`}</p>
                            {/* <p className='text-lg font-extrabold text-white'>{jobData?.data?.primary_location_reference_evergreen.slice(4) || `${t('NoInformationAvailable')}`}</p> */}
                        </div>

                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('partTime_fullTime')}</p>
                            <p className='text-lg font-extrabold text-white'>{data?.data?.position_time_type_id === 'Full_time' ? `${t('fullTime')}` : `${t('partTime')}` || `${t('NoInformationAvailable')}`}</p>
                        </div>

                        {/* <div className='mb-4'>
                        <p className="text-sm font-thin">Shift</p>
                        <p className='text-lg font-extrabold text-white'>{data?.data?.scheduled_weekly_hours || `${t('NoInformationAvailable')}`} hours</p>
                    </div> */}
                    </div>


                    <div className="w-full border-b-2 border-green-50 h-0.5 opacity-10 my-3" />

                    <div className="mt-5">

                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('WOTCStatus')}</p>
                            {/* @ts-ignore */}
                            <p className='text-lg font-extrabold text-white'>{wocFormat(data?.data?.assessment_test_status_reference)}</p>
                        </div>
                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('backgroundCheckStatus')}</p>
                            {/* @ts-ignore */}

                            <p className='text-lg font-extrabold text-white'>{backgroundFormat(data?.data?.background_check_overall_status_reference)}</p>
                        </div>
                        <div className='mb-4'>
                            <p className="text-sm font-thin">{t('drugScreenStatus')}</p>
                            {/* @ts-ignore */}
                            <p className='text-lg font-extrabold text-white'>{drugFormat(data?.data?.drugscreen_status)}</p>
                        </div>


                    </div>

                </div>

                {/* <Stepper activeStep={currentPosition} orientation="vertical" className="p-5"
                         connector={<CustomizedConnector/>}>
                    {steps?.map((step, index) => {

                        return <Step
                            key={index}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: 'white', // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'white', // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-root .Mui-disabled': {
                                    color: 'grey.500', // circle color (COMPLETED)
                                }

                            }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <div className="space-y-1">
                                    <p className={`${index === currentPosition && 'font-black'} text-lg`}>{step.name}</p>
                                    {
                                        index === currentPosition ?
                                            <p className="font-serif text-md">This is your current state</p> : null
                                    }
                                </div>
                            </StepLabel>
                        </Step>


                    })}
                </Stepper> */}
            </div>
        </div>
    );
}

export default VerticalLinearStepper