import React, { FC, memo, useContext, useMemo, useState } from 'react';
import Button from "../General/Button";
import { Content_Individual } from "../../types/User";
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../context/MainProvider';

const VideoPage: FC<{ content: Content_Individual }> = memo(({ content }) => {
  // const { t } = useTranslation();
  const [isPlay, setPlay] = useState(false)
  const addDefaultSrc = (ev: any) => ev.target.src = "https://www.fedex.com/content/dam/fedex/us-united-states/about-us/images/2020/Q4/EU_EN_2020_1_web_jpg_NA_connect_possibilities_home_marketing_673415817_smaller.jpg"
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {mainState} =useContext(MainContext)

  const body = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.video.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.video[0].body;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.video.filter(
        (item: any) => item.language === currentLanguage
      )[0].body
    } else return content?.data?.video?.body;
  }, [content, currentLanguage]);

  const file = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.video.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.video[0].file;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.video.filter(
        (item: any) => item.language === currentLanguage
      )[0].file
    } else return content?.data?.video?.file;
  }, [content, currentLanguage]);

  const cover_image = useMemo(() => {

    if (content.data.template_id) {
      if (!content.data.content_template.content_metadata.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.content_metadata[0].cover_image;
      }
      else {
        return content.data.content_template.content_metadata.filter(
          (item: any) => item.language === currentLanguage)[0].cover_image
      }
    } else {
      return content?.data?.cover_image;
    }
  }, [content, currentLanguage]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-transparent">
      {
        isPlay ? <>
          {
            file &&
            <video controls className="rounded-md" autoPlay src={file}></video>
          }
        </> : <img className="w-full shadow-md rounded-t-md max-h-[15rem]" src={cover_image}
          onError={addDefaultSrc} alt="" />
      }

      <div
        className="relative flex flex-col items-center w-full p-4 mt-0 text-black whitespace-pre-wrap bg-white shadow-md rounded-b-md">
        <Button 
           customStyles={{'backgroundColor': mainState.baseColor  }}
        onClick={() => setPlay(prev => !prev)} label={isPlay ? t('stopVideo') : t('playVideo')}
          extraClasses="w-fit text-white" />
        {
          body !== "" ?
            <p className="mt-4 text-sm text-center">{body}</p> : null
        }
      </div>

    </div>
  );
});

export default VideoPage;