import React, { FC, memo, useContext, useMemo } from "react";
import { MdCheckCircle } from "react-icons/md";
import { Content_Individual } from "../../types/User";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../context/MainProvider";

export type listItem = {
  title: string;
};

const ChecklistItem = (prop: listItem) => {

  const {mainState} = useContext(MainContext)
  return (
    <div className="flex flex-row mb-4">
      <div className="mr-3">
        <MdCheckCircle className={`text-2xl`} style={{ color:mainState.baseColor }} />
      </div>
      <div className="w-full text-gray-600">{prop.title}</div>
    </div>
  );
};

const ChecklistPage: FC<{ content: Content_Individual }> = memo(
  ({ content }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const items = useMemo(() => {
      if (content.data.template_id) {
        if (!content.data.content_template.checklist.find(
          (item: any) => item.language === currentLanguage
        )) {
          return content.data.content_template.checklist[0].items;
        }
        // return content.data.content_template.article[0].body;
        return content.data.content_template.checklist.filter(
          (item: any) => item.language === currentLanguage
        )[0].items
      } else return content?.data?.checklist?.items;
    }, [content, currentLanguage]);

    // const items = useMemo(() => {
    //   if (content.data.template_id) {
    //     return content.data.content_template.checklist[0].items;
    //   } else return content?.data?.checklist?.items;
    // }, [content]);

    return (
      <div className="flex flex-col w-full h-full p-4 whitespace-pre-wrap bg-white rounded-b-md">
        {items?.map((item: any, index: number) => (
          <ChecklistItem key={index} title={item} />
        ))}
      </div>
    );
  }
);

export default ChecklistPage;
