/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, {
  ButtonHTMLAttributes,
  FC,
  ReactElement,
  ReactNode,
  forwardRef,
} from "react";
import classNames from "classnames";
import { Spinner } from "./AppButtonSpinner";

type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";
type Size = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
export type AppButtonProps = {
  type: "submit" | "reset" | "button";
  variant: Variant;
  disabled?: boolean;
  children: ReactElement | ReactNode | string;
  size?: Size;
  extendClass?: string;
  isLoading?: boolean;
  isFull?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryClasses = "bg-primary hover:shadow-md text-textDark";
const DangerClasses = "bg-[#eb004e] hover:shadow-md text-white";
const DefaultClasses =
  "bg-white hover:bg-gray-200 text-gray-500 border border-gray-200";
const SecondaryClasses =
  "bg-gray-700 hover:text-gray-700 hover:bg-white text-white border border-gray-200";
const InfoClasses = "bg-blue-500 hover:bg-blue-600 text-white";
const SuccessClasses = "bg-greenColor hover:shadow-md text-white";

const selectVariant = (variant: Variant) => {
  switch (variant) {
    case "primary":
      return PrimaryClasses;
    case "danger":
      return DangerClasses;
    case "light":
      return DefaultClasses;
    case "secondary":
      return SecondaryClasses;
    case "info":
      return InfoClasses;
    case "success":
      return SuccessClasses;
    default:
      return DefaultClasses;
  }
};

const animation = () => {
  return "transition-all";
};

const selectSize = (size: Size) => {
  switch (size) {
    case "xs":
      return "px-1 py-0.5 text-xs";
    case "sm":
      return "px-2 py-1 text-xs";
    case "md":
      return "px-3 py-1.5 text-sm";
    case "lg":
      return "px-4 py-2 text-lg";
    case "xl":
      return "px-6 py-2 text-lg";
    case "2xl":
      return "px-8 py-4 text-lg";
    case "3xl":
      return "px-10 py-6 text-lg";
    default:
      return "px-12 py-8 text-base";
  }
};

export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (
    {
      onClick,
      size,
      variant,
      type,
      children,
      extendClass,
      disabled,
      isLoading,
      isFull,
      className,
      ...otherProps
    },
    ref
  ) => {
    const classes = classNames(
      extendClass,
      "flex justify-center h-full items-center custom__btn",
      size && selectSize(size),
      selectVariant(variant),
      isLoading && "cursor-not-allowed opacity-75",
      isFull && "w-full",
      animation(),
      className
    );

    return (
      <button
        ref={ref}
        onClick={onClick}
        type={type || "button"}
        disabled={isLoading || disabled}
        className={classes}
        {...otherProps}
      >
        {isLoading ? (
          <Spinner height={22} />
        ) : (
          <div className="flex gap-1 mx-1 my-1">{children}</div>
        )}
      </button>
    );
  }
);
// AppButton.defaultProps = {
//   type: "button",
//   size: "sm",
//   variant: "default",
//   isLoading: false,
//   isFull: false,
//   disabled: false,
//   extendClass: "",
//   className: "",
//   onClick: () => {},
// };
