import React, { FC, memo, useContext, useMemo } from "react";
import { Content_Individual } from "../../types/User";
import { MdAnnouncement } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../context/MainProvider";

const Announcement: FC<{ content: Content_Individual }> = memo(
  ({ content }) => {
    const { t, i18n } = useTranslation();

    const { mainState} = useContext(MainContext)
    const currentLanguage = i18n.language;

    const body: string = useMemo(() => {
      if (content.data.template_id) {
        if (!content.data.content_template.announcement.find(
          (item: any) => item.language === currentLanguage
        )) {
          return content.data.content_template.announcement[0].body;
        }
        // return content.data.content_template.article[0].body;
        return content.data.content_template.announcement.filter(
          (item: any) => item.language === currentLanguage
        )[0].body
      } else {
        // console.log('content',content)
        return content?.data?.announcement?.body;
      }
    }, [content, currentLanguage]);

    // const body: string = useMemo(() => {
    //   if (content.data.template_id) {
    //     return content.data.content_template.announcement[0].body;
    //   } else return content?.data?.announcement?.body;
    // }, [content]);

    return (
      <div className="relative p-4 mt-6 text-black whitespace-pre-wrap bg-gray-100 rounded-md shadow-md">
        <div className="flex items-center justify-center w-full align-center">
          <div style={{ backgroundColor:mainState.baseColor }} className="absolute left-auto right-auto p-3 text-2xl text-white rounded-full shadow-md -top-5">
            <MdAnnouncement />
          </div>
        </div>
        {
          <div
            className="pt-10 pb-4 text-sm text-center"
            dangerouslySetInnerHTML={{
              __html: body || "",
            }}
          />
        }
      </div>
    );
  }
);

export default Announcement;
