import { useContext, useEffect } from "react";
import { getToken } from "../utils/LocalStorage";
import { WS_URL } from "../config";
import { MainContext } from "../context/MainProvider";
import useWebSocket from "react-use-websocket";
import { store } from "../store/store";
import axios from "axios";
import i18n from "../i18n";

export const getActionById = async (
  organization: number,
  pathway: number,
  state: number,
  action: number,
  uuid: string
) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const reqUrl =
    baseURL +
    `users/${uuid}/actions?organization=${organization}&pathway=${pathway}&state=${state}&action=${action}`;
  try {
    const response = await axios.get(reqUrl, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const NotificationService = () => {
  const { mainState } = useContext(MainContext);
  const token = getToken();
  const webSocketURL = `${WS_URL}${token}`;
  const { state } = useContext(store);
  const uuid = state.user?.sub || "";

  useEffect(() => {
    if (window) {
      setTimeout(async () => await askPermission(), 2000);
    }
  }, []);

  useWebSocket(webSocketURL, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },

    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
    onMessage: async (event) => {
      if (gettingNotificationPermission() === "granted") {
        showNotificationByServiceWorker(event.data);
      }
    },
    reconnectInterval: 2000,
  });

  const showNotificationByServiceWorker = (message: string) => {
    const parsedMessage = JSON.parse(message);

    let notificationMessage = "";
    let alTitle = "";
    let alBody = "";
    // if (parsedMessage.action !== "al_notification") {
    //   notificationMessage = parsedMessage;
    //   navigator.serviceWorker.ready
    //     .then(function (registration) {
    //       registration.showNotification(mainState.organization.name, {
    //         body: parsedMessage.notification_msg,
    //         vibrate: [200, 100, 200],
    //         // icon: '/path-to-your-icon.png',
    //         icon: mainState.logo,
    //         dir: "ltr",
    //         data: {
    //           name: mainState.organization.name,
    //         },
    //         requireInteraction: false,
    //       });
    //       // console.log('controller',navigator.serviceWorker.controller)
    //       navigator.serviceWorker.controller?.postMessage({
    //         type: "NOTIFICATION",
    //         payload: {
    //           notificationMessage,
    //           appName: "Custom name 2",
    //         },
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error showing notification:", error);
    //     });
    // }
    if (parsedMessage.action === "al_notification") {
      //get the action data before sending notiification to user
      getActionById(
        parsedMessage.organization_id,
        parsedMessage.pathway_id,
        parsedMessage.state_id,
        parsedMessage.action_id,
        uuid
      )
        .then((action) => {
          // map through the action metaData to get the corresponding language and show it to the user
          const matchingLanguageItem =
            action.data.action_template.action_metadata.find(
              (item: { language: string }) => item.language === i18n.language
            );

          alTitle = matchingLanguageItem
            ? matchingLanguageItem.title
            : action.data.action_template.action_metadata[0].title;
          alBody = matchingLanguageItem
            ? matchingLanguageItem.al_message
            : action.data.action_template.action_metadata[0].al_message;

          if (alTitle === "" || null || alBody === "" || null) {
            return;
          }
          if ("serviceWorker" in navigator && "PushManager" in window) {
            navigator.serviceWorker.ready
              .then(function (registration) {
                registration.showNotification(alTitle, {
                  body: alBody,
                  vibrate: [200, 100, 200],
                  icon: mainState.logo,
                  dir: "ltr",
                  data: {
                    name: mainState.organization.name,
                  },
                  requireInteraction: false,
                });

                navigator.serviceWorker.controller?.postMessage({
                  type: "NOTIFICATION",
                  payload: {
                    notificationMessage,
                  },
                });
              })
              .catch((error) => {
                console.error("Error showing notification:", error);
              });
          }
          // console.log("Title:", alTitle);
          // console.log("Message:", alBody);
        })
        .catch((error) => {
          console.error("Error getting action:", error);
        });
    }

    if (parsedMessage === "Welcome") return;
  };
};

export const gettingNotificationPermission = () => {
  if ("Notification" in window) {
    // Check the current notification permission status
    if (Notification.permission === "granted") {
      // console.log("Notification permission is already granted");
      return "granted";
    } else if (Notification.permission === "denied") {
      console.log("Notification permission is denied");
      return "denied";
    } else {
      // Request permission after user interaction
      try {
        const permissionResult = Notification.requestPermission();
        console.log("permissionResult", permissionResult);

        permissionResult
          .then((result) => {
            if (result === "granted") {
              console.log("Notification permission granted");
            } else {
              console.log("Notification permission denied");
            }
          })
          .catch((error) => {
            console.error("Error requesting notification permission:", error);
            throw error;
          });

        return permissionResult;
      } catch (error) {
        console.error("Error requesting notification permission:", error);
        throw error;
      }
    }
  } else {
    console.error("Notifications not supported in this browser");
    throw new Error("Notifications not supported");
  }
};

export const askPermission = () => {
  return new Promise((resolve, reject) => {
    if ("Notification" in window) {
      // Check the current notification permission status
      if (Notification.permission === "granted") {
        // console.log("Notification permission is already granted");
        resolve("granted");
      } else if (Notification.permission === "denied") {
        console.log("Notification permission is denied");
        resolve("denied");
      } else {
        // Request permission after user interaction
        Notification.requestPermission()
          .then((permissionResult) => {
            console.log("permissionResult", permissionResult);

            if (permissionResult === "granted") {
              console.log("Notification permission granted");
            } else {
              console.log("Notification permission denied");
            }
            resolve(permissionResult);
          })
          .catch((error) => {
            console.error("Error requesting notification permission:", error);
            reject(error);
          });
      }
    } else {
      console.error("Notifications not supported in this browser");
      reject(new Error("Notifications not supported"));
    }
  });
};

// const showNotification = (message: string) => {
//   if ('Notification' in window) {
//     // Request permission to show notifications
//     Notification.requestPermission().then((permission) => {
//       console.log('JSON.parse(message)', message);
//       if (permission === 'granted') {
//         try {
//           const parsedMessage = JSON.parse(message);
//           const notificationMessage =
//             typeof parsedMessage === 'string'
//               ? parsedMessage
//               : parsedMessage.message;

//           new Notification(mainState.organization.name, {
//             renotify: true,
//             vibrate: [200, 100, 200],
//             body: notificationMessage,
//             icon: mainState.logo,
//           });
//         } catch (error) {
//           console.error('Error parsing JSON:', error);
//         }
//       } else {
//         console.log('Notification permission denied');
//       }
//     });
//   } else {
//     console.log('Notifications not supported by this browser');
//   }
// };
