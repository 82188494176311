import React, { FC, memo, useMemo, useState } from "react";
import { Content_Individual } from "../../types/User";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Button from "../General/Button";
import { MdCheckCircle } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Selector: FC<{
  content: Content_Individual;
  submitSelectorForm: (item: string, variant: string) => void;
}> = memo(({ content, submitSelectorForm }) => {
  const [selectedIndex, setIndex] = useState({
    item: 0,
    variants: 0,
  });
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const items = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.selector.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.selector[0].items;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.selector.filter(
        (item: any) => item.language === currentLanguage
      )[0].items
    } else return content?.data?.selector?.items;
  }, [content, currentLanguage]);

  const variants = useMemo(() => {
    if (content.data.template_id) {
      if (!content.data.content_template.selector.find(
        (item: any) => item.language === currentLanguage
      )) {
        return content.data.content_template.selector[0].itvariantsems;
      }
      // return content.data.content_template.article[0].body;
      return content.data.content_template.selector.filter(
        (item: any) => item.language === currentLanguage
      )[0].variants
    } else return content?.data?.selector?.variants;
  }, [content, currentLanguage]);


  // const items = useMemo(() => {
  //   if (content.data.template_id) {
  //     return content.data.content_template.selector[0].items;
  //   } else return content?.data?.selector?.items;
  // }, [content]);

  // const variants = useMemo(() => {
  //   if (content.data.template_id) {
  //     return content.data.content_template.selector[0].variants;
  //   } else return content?.data?.selector?.variants;
  // }, [content]);

  const submitForm = () => {
    const item = items?.find(
      (_: any, ind: number) => ind === selectedIndex.item
    )?.title as string;
    const variant = variants?.find(
      (_: any, ind: number) => ind === selectedIndex.variants
    ) as string;
    submitSelectorForm(item, variant);
  };

  return (
    <div>
      {/*-------------------------------------------------------------------*/}
      {/*The list of available Selector Items*/}
      <Swiper
        navigation={{
          nextEl: ".review-swiper-button-next",
          prevEl: ".review-swiper-button-prev",
        }}
        slidesPerView={6}
        centeredSlides={true}
        className="mySwiper"
      >
        {items?.map((item: any, index: number) => (
          <SwiperSlide
            key={index}
            onClick={() => setIndex({ ...selectedIndex, item: index })}
          >
            <div className="h-14 w-14">
              <img
                src={item?.image}
                alt="img"
                className={`w-full h-full rounded-full shadow-md ${index === selectedIndex.item && "border-2"
                  }`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/*-------------------------------------------------------------------*/}
      {/*The currently selected Selector Item*/}
      {
        <div className="flex items-center justify-center py-5">
          {items && (
            <img
              src={items[selectedIndex.item]?.image}
              alt="selectedIndex"
              className="rounded-md shadow-md h-60 w-60"
            />
          )}
        </div>
      }

      {/*-------------------------------------------------------------------*/}
      {/*The list of Selector Item variants*/}
      <div className="flex flex-wrap items-center justify-center pb-8">
        {variants?.map((item: any, index: number) => (
          <div
            onClick={() => setIndex({ ...selectedIndex, variants: index })}
            key={index}
            className={`bg-white text-black pt-2 pb-2 pl-3 pr-3 ml-1 mr-1 rounded-md ${index === selectedIndex.variants && "border-2 border-teal-500"
              }`}
          >
            <p>{item}</p>
          </div>
        ))}
      </div>

      <Button
        icon={<MdCheckCircle className="mx-1" />}
        onClick={submitForm}
        label={t("completeSelector")}
        extraClasses={"bg-[#9263BD] opacity-90"}
      />
    </div>
  );
});

export default Selector;
