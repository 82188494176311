export const VideoIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >
      <path fill="currentColor" d="M398.924 727.999l236.921-153.614-236.921-153.23v306.844zm-241.23 148q-23.616 0-40.654-17.039-17.039-17.038-17.039-40.654V333.694q0-23.616 17.039-40.654 17.038-17.039 40.654-17.039h644.612q23.616 0 40.654 17.039 17.039 17.038 17.039 40.654v484.612q0 23.616-17.039 40.654-17.038 17.039-40.654 17.039H157.694z"></path>
    </svg>
  )
}