import { useContext, useEffect, useMemo, useState } from "react";
import { OverlayModal } from "../../Theme/Overlay/Overlay";
import { MainContext } from "../../context/MainProvider";
import { getPWAInstallPrompt, setPWAInstallPrompt } from "../../utils/LocalStorage";
import logo from './../../assets/logo192.png'
import { AppButton } from "../../Theme/Shared/AppButton";
import { useTranslation } from "react-i18next";
import { usePWAInstall } from 'react-use-pwa-install'
import { CloseIcon } from "../../Theme";

const PWAInitailizer: React.FC = () => {

    const installAndroid = usePWAInstall()

    const { t } = useTranslation();
    
    const { mainState, setMainState } = useContext(MainContext);
    const [bipEvent, setBipEvent] = useState<Event | null>(null);
    const [iosSetup, setIOSetup] = useState(false)


    const isPWAInstalled= useMemo(() => {
        // Check if running in standalone mode on iOS
        if ( (navigator as any).standalone || window.matchMedia("(display-mode: standalone)").matches) {return true;}
        return false;
   }, [])

   useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
        event.preventDefault();
        setBipEvent(event)
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Clean up the event listener when the component is unmounted
            return () => {
                window.removeEventListener(
                    "beforeinstallprompt",
                    handleBeforeInstallPrompt
                );
            };
    }, []);


    useEffect(() => {
        if (!getPWAInstallPrompt() && !mainState.isInstalledPWA && !isPWAInstalled ) {
            setMainState((prev) => ({ ...prev, isInstalledPWA: true }));
        }
    }, [isPWAInstalled, mainState.isInstalledPWA, setMainState]);
    


    useEffect(() => {
        if(!navigator.onLine && isPWAInstalled){
            setMainState((prev) => ({ ...prev, isInstalledPWA: false }));
            setPWAInstallPrompt("true");
        }
    },[isPWAInstalled])

    useEffect(() => {
        if(isPWAInstalled) {
            setPWAInstallPrompt("true");
        }
    },[isPWAInstalled])

    const installPWAHandler = () => {
        const isIOS =
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        if (isIOS) {
            setIOSetup(true)
            setPWAInstallPrompt("true");

        } else if (bipEvent) {
            // Show the install prompt
            (bipEvent as any).prompt();

            // Wait for the user to respond to the prompt
            (bipEvent as any).userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the install prompt");
                    setPWAInstallPrompt("true");
                } else {
                    console.log("User dismissed the install prompt");
                    setPWAInstallPrompt("false");
                }
            });
        }
    };

    return (
        <OverlayModal
            isOpen={mainState.isInstalledPWA}
            onClose={() =>
                setMainState((prev) => ({ ...prev, isInstalledPWA: false }))
            }
        >
            <div className="flex flex-col items-center justify-between w-3/5 px-4 py-5 bg-white rounded-md t">
                <div className="flex flex-col items-center justify-center">
                  {
                    iosSetup ? ( <div className="flex justify-end flex-1 w-full">
                    <button onClick={() =>setMainState((prev) => ({ ...prev, isInstalledPWA: false })) }><CloseIcon className="w-4 h-4 text-gray-400" /></button>
                    </div>) : null 
                  }
                    <img src={logo} alt="" className="w-12 h-12 mb-2" />
                    <h2 className="text-center text-gray-600 text-md">
                        {t('addActionLogicsTo')} <br /> {t('yourHomeScreen')}
                    </h2>

                    {
                        iosSetup ? (
                            <>
                                <p className="my-2 text-sm">({t('SafariBrowserRequired')})</p>
                                <hr className="w-full h-px my-3 border-gray-100 border-1" />
                                <ol className="pl-3 my-1">
                                    <li className="mb-1 text-sm text-gray-800">
                                       {t('tapTheShareButtton')}
                                    </li>
                                    <li className="mb-1 text-sm text-gray-800">
                                        {t('scrollDownAndSelect')}
                                    </li>
                                </ol>
                            </>
                        ) : null
                    }
                </div>
                <div className="flex items-center justify-between w-full px-2">
                    {
                        iosSetup ? null : (<><AppButton
                            type="button"
                            variant="info"
                            extendClass=""
                            size="xs"
                            onClick={() => {
                            const isIOS =
                                /iPad|iPhone|iPod/.test(navigator.platform) ||
                                (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
                            if (isIOS) {
                                setIOSetup(true)
                                setPWAInstallPrompt("true");  
                            }
                            else {
                                installAndroid()
                                // let bibAndroid:any = null;
                                // window.addEventListener("beforeinstallprompt", event => {
                                //     event.preventDefault();
                                //     console.log('amr',event)
                                //     bibAndroid = event;
                                // });

                                // console.log("bib", bibAndroid)
                             
                                // if(bibAndroid) { 
                                //     console.log("bib", bibAndroid)
                                //     bibAndroid.prompt();
                                //     bibAndroid.userChoice.then((choiceResult: any) => {
                                //         if (choiceResult.outcome === "accepted") {
                                //             console.log("User accepted the install prompt");
                                //             setPWAInstallPrompt("true");
                                //         } else {
                                //             console.log("User dismissed the install prompt");
                                //             setPWAInstallPrompt("false");
                                //         }
                                //     });
                                // }
                           
                            }
                            }}
                        >
                            {t('addApplication')}
                        </AppButton>
                            <button
                                className="p-2 text-xs text-gray-700"
                                onClick={() => {
                                    setMainState((prev) => ({ ...prev, isInstalledPWA: false }))
                                    setPWAInstallPrompt("true");
                                }
                                }
                            >
                                {t('continueInBrowser')}
                            </button>
                        </>)
                    }
                </div>
            </div>
        </OverlayModal>
    )
};


export default PWAInitailizer;