import API from "..";

export const getAllNotifications = async (limit:number = 100) => {
    const { data } = await API.get(`/worker/notification/?limit=${limit}`)
    return data
}

export const getAllUserUnviewedNotification = async (limit:number = 25) => {
    const { data } = await API.get(`/worker/notification/worker/unviewed?limit=${limit}`)
    return data
}

export const getAllUserNotification = async (limit:number = 100) => {
    const { data } = await API.get(`/worker/notification/worker?limit=${limit}`)
    return data
}

export const setUserNotificationsAsViewed = async (notification_ids: number[]) => {
    const {data} = await API.post(`/worker/notification/worker/viewed`, {
        notification_ids
    })
    return data
}

export const setAllUserNotificationAsViewed = async () => { 
    const {data} = await API.post(`/worker/notification/worker/all_viewed`)
    return data
}