import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { removePathways } from "./LocalStorage";

export const Auth0ProviderWithHistory = ({
    children,
}: PropsWithChildren<any>): JSX.Element | null => {
    const navigate = useNavigate();

     const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN as string
     const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT as string

    const onRedirectCallback = (appState: AppState | undefined) => {
        removePathways()
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId)) {
        return null;
    }


    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation='localstorage'
        >
            {children}
        </Auth0Provider>
    );
};