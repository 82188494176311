import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ArticlePage from "./Article";
import VideoPage from "./Video";
import ChecklistPage from "./Checklist";
import ContentTypeContainer from "./ContentTypeContainer";
import Faq from "./Faq";
import Direction from "./Direction";
import Announcement from "./Announcement";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Content_Individual, InputPropsModal } from "../../types/User";
import { formData } from "../../types/Content";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  getContent,
  submitConsent,
  submitContent,
  submitSelector,
  submitSurvey, submitSurveySeq
} from "../../QueryHook/Contents/contents.apis";
import Selector from "./Selector";
// import { CircularProgress } from "@mui/material";
import Consent from "./Consent";
import Survey from "./Survey";
import { store } from "../../store/store";
import SurveySequence from "./SurveySequence";
import { MainContext } from '../../context/MainProvider';
import { AppRoutes } from '../../routes';

const ContentTypeModal: FC<InputPropsModal> = memo(({ ...props }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get('val');
  const { setMainState, mainState } = useContext(MainContext)

  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const { state } = useContext(store);

  const uuid = state.user?.sub || ""

  const currentParticipantOrganizationId: number = useMemo(() => {
    if(mainState.organization_id === 0) {
      const currentParticipant = mainState.allWorkerParticipants?.find((participant: any) => participant.slug === props.participantSlug)
      return currentParticipant?.organization_id
    } else {
      return mainState.organization_id
    }
  }, [mainState.allWorkerParticipants, mainState.organization_id, props.participantSlug])

  const handleSubmit = useMutation((formData: formData) => submitContent(formData), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['contents-new', props.participantId])
      await queryClient.invalidateQueries(['contents-seen', props.participantId])
    }
  })

  const makeInteractedTrue = async () => {
    await handleSubmit.mutateAsync({
      content: props.id,
      data: { interacted: true },
      organ: currentParticipantOrganizationId,
      participant: props.participantId,
      uuid: uuid,
      participantSlug: props.participantSlug,
      contentSlug: props.contentSlug as string
    })
    await queryClient.invalidateQueries([props.type, props.id])
    setMainState(prev => {
      return {
        ...prev,
        openContentPage: false
      }
    })
    props.onClose()
  }

  const handleConsentSubmit = useMutation((formData: formData) => submitConsent(formData), {
    onSuccess: async () => await makeInteractedTrue()
  })

  const handleSurveySubmit = useMutation((formData: formData) => submitSurvey(formData), {
    onSuccess: async () => await makeInteractedTrue()
  })

  const handleSelectorSubmit = useMutation((formData: formData) => submitSelector(formData), {
    onSuccess: async () => await makeInteractedTrue()
  })

  const handleSurveySeqSubmit = useMutation((formData: formData) => submitSurveySeq(formData), {
    onSuccess: async () => {
      // await makeInteractedTrue()
      if (data?.data?.survey_sequence?.surveys?.length === Number(param) + 1) {
        // console.log("the end!!!")
        await makeInteractedTrue()
      }
    }
  })

  const submitConsentForm = async (val: boolean) => {
    // if (props.isInteracted) return

    //  const req =  {
    //     consentData: { consent_accepted: val, consent_response_time: (new Date()).toJSON() },
    //     // content: props.id,
    //     organ: currentParticipantOrganizationId,
    //     // participant: props.participantId,
    //     uuid: uuid,
    //     participantSlug: props.participantSlug,
    //     contentSlug: props.contentSlug as string
    //   }
    await handleConsentSubmit.mutateAsync({
      consentData: { consent_accepted: val, consent_response_time: (new Date()).toJSON() },
      // content: props.id,
      organ: currentParticipantOrganizationId,
      // participant: props.participantId,
      uuid: uuid,
      participantSlug: props.participantSlug,
      contentSlug: props.contentSlug as string
    })

    setMainState(prev => {
      return {
        ...prev, 
        openContentPage: false
      }
    })
    // Refresh the page
    // window.location.reload();
    navigate(AppRoutes.STATE_FLOW)
  }

  const submitSurveySeqForm = async (val: number) => {
    if (props.isInteracted) return
    const findSurvey = data?.data?.survey_sequence?.surveys?.find((_, i) => i === Number(param))
    const findItem = findSurvey?.survey_items?.find((_, ind) => ind === val)?.id

    await handleSurveySeqSubmit.mutateAsync({
      surveyData: { survey_response: findItem },
      content: props.id,
      organ: currentParticipantOrganizationId,
      participant: props.participantId,
      uuid: uuid,
      survey_sequence: data?.data?.survey_sequence?.id,
      survey: findSurvey?.id,
      participantSlug: props.participantSlug,
      contentSlug: props.contentSlug as string
    })


    // if (data?.data?.survey_sequence?.surveys?.length === Number(param) + 1) console.log("the end!!!")
  }

  const submitSurveyForm = async (val: number) => {
    if (props.isInteracted) return
    await handleSurveySubmit.mutateAsync({
      surveyData: { survey_response: val },
      content: props.id,
      organ: currentParticipantOrganizationId,
      participant: props.participantId,
      uuid: uuid,
      participantSlug: props.participantSlug,
      contentSlug: props.contentSlug as string
    })
  }

  const submitSelectorForm = async (item: string, variant: string) => {
    if (props.isInteracted) return
    await handleSelectorSubmit.mutateAsync({
      selectorData: { selector_item: item, selector_variant: variant },
      content: props.id,
      organ: currentParticipantOrganizationId,
      participant: props.participantId,
      uuid: uuid,
      participantSlug: props.participantSlug,
      contentSlug: props.contentSlug as string
    })
  }

  const {
    isLoading,
    isError,
    data,
    error,
    isFetching
  } = useQuery<Content_Individual, Error>([props.type, props.id], () => getContent(currentParticipantOrganizationId, props.contentSlug as string, props.type, props.participantSlug), {
    enabled: !!props.id || props.isOpen,
    onSuccess: async () => {
      // if (!['selector', 'consent', 'survey', 'survey_sequence'].includes(props.type) && !props.isInteracted) {
      if (!['selector', 'consent', 'survey', 'survey_sequence'].includes(props.type) && !props.isInteracted) {
        await handleSubmit.mutateAsync({
          content: props.id,
          data: { interacted: true },
          organ: currentParticipantOrganizationId,
          participant: props.participantId,
          uuid: uuid,
          participantSlug: props.participantSlug,
          contentSlug: props.contentSlug as string
        })
      }
    }
  })

  useEffect(() => {
    if (param) {
      // 👇️ delete each query param
      searchParams.delete('val');

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  }, [props.isOpen])

  return (
    <div>
      {
        props.isOpen &&
        <div className="overlay">
          {
            data ? <ContentTypeContainer handleModal={props.onClose} id={props.id} type={props.type}
              content={data}>
              <div className="w-full">
                {(() => {
                  switch (props.type) {
                    case 'article':
                      return <ArticlePage content={data} />
                    case 'video':
                      return <VideoPage content={data} />
                    case 'checklist':
                      return <ChecklistPage content={data} />
                    case 'faq':
                      return <Faq content={data} />
                    case 'direction':
                      return <Direction content={data} />
                    case 'announcement':
                      return <Announcement content={data} />
                    case 'consent':
                      return <Consent content={data} submitConsentForm={submitConsentForm} />
                    case 'selector':
                      return <Selector content={data}
                        submitSelectorForm={submitSelectorForm} />
                    case 'survey':
                      return <Survey participantSlug={props.participantSlug} />
                    case 'survey_sequence':
                      return <SurveySequence participantSlug={props.participantSlug} submitSurveySeqForm={submitSurveySeqForm} />
                    default:
                      return null
                  }
                })()}
              </div>
            </ContentTypeContainer> : null
          }
          {/* {(isLoading || handleConsentSubmit.isLoading || handleSelectorSubmit.isLoading || handleSurveySubmit.isLoading) ? (
            <div className="flex items-center justify-center flex-1 w-full h-screen bg-white">
              <CircularProgress />
            </div>
          ) :
            isError ? (
              <span>Error: {error?.message || "Something went wrong!"}</span>
            ) : data ?
              <ContentTypeContainer handleModal={props.onClose} id={props.id} type={props.type}
                content={data}>
                <div className="w-full">
                  {(() => {
                    switch (props.type) {
                      case 'article':
                        return <ArticlePage content={data} />
                      case 'video':
                        return <VideoPage content={data} />
                      case 'checklist':
                        return <ChecklistPage content={data} />
                      case 'faq':
                        return <Faq content={data} />
                      case 'direction':
                        return <Direction content={data} />
                      case 'announcement':
                        return <Announcement content={data} />
                      case 'consent':
                        return <Consent content={data} submitConsentForm={submitConsentForm} />
                      case 'selector':
                        return <Selector content={data}
                          submitSelectorForm={submitSelectorForm} />
                      case 'survey':
                        return <Survey participantSlug={props.participantSlug} />
                      case 'survey_sequence':
                        return <SurveySequence participantSlug={props.participantSlug} submitSurveySeqForm={submitSurveySeqForm} />
                      default:
                        return null
                    }
                  })()}
                </div>
              </ContentTypeContainer> : null
          } */}
        </div>
      }
    </div>
  );
});

export default ContentTypeModal;