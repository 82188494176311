import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Content_Individual } from "../../types/User";
import { MdCheckCircle, MdNoteAlt, MdArrowForwardIos } from "react-icons/md";
import Button from "../General/Button";
import { RiSurveyFill } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SurveyItem, SurveySequenceContent, SurveySequenceResponse, SurveySequenceType } from '../../types/SurveySequenceTypes';
import { MainContext } from '../../context/MainProvider';
import { getSurveySequence } from '../../QueryHook/Contents/contents.apis';
import SurveySequenceHeader from '../SurveySequence/SurveySequenceHeader';
import axios from 'axios';
import { store } from '../../store/store';
import { getToken } from '../../utils/LocalStorage';
import { useTranslation } from 'react-i18next';
import { ContentTemplateIcons } from '../../Theme';
import { AppRoutes } from '../../routes';


const findSurveySequenceByLanguage= (content_template:any, langauge:string) => {
    const findSurveySequenceByLanguage = content_template.survey_sequence.find((i:any) => i.language === langauge)
    if(findSurveySequenceByLanguage) { 
        return findSurveySequenceByLanguage
    } else{
        return content_template.survey_sequence[0]
    }
}

const SurveySequence: FC<{ participantSlug: string, submitSurveySeqForm: (val: number) => void }> = memo(({ participantSlug, submitSurveySeqForm }) => {

    // console.log('participant id is', participantSlug)
    // console.log('sequence is loaded')
    const { t, i18n } = useTranslation();

    const { state } = useContext(store);
    const uuid = state.user?.sub || ""
    const { mainState, setMainState } = useContext(MainContext)

    const [currentSurvey, setCurrentSurvey] = useState<number>(0)

    const [selectedItem, setSelectedItem] = useState<SurveyItem>()

    const [totalSurvey, setTotalSurvey] = useState<number>(0)

    const [existContentSurveySequence,setExistContentSurveySequence ] = useState<any>()


    const navigate = useNavigate()

    const currentParticipantOrganizationId: number = useMemo(() => {
        // const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
        // return currentParticipant?.organization_id
        if(mainState.organization_id === 0) {
            const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
            return currentParticipant?.organization_id
          } else {
            return mainState.organization_id
          }
    },[mainState.allWorkerParticipants, mainState.organization_id, participantSlug])


    // console.log('participant organization id',currentParticipantOrganizationId)

    useEffect(() => {

        if (mainState.contentSlug && participantSlug) {
            getSurveySequence(currentParticipantOrganizationId, mainState.contentSlug, participantSlug).then((data: SurveySequenceType) => {

                if (data) {

                    let sortingSurvyesData: SurveySequenceType;

                    
                    if (data.content.template_id) {
                        // console.log('content_template',data.content.content_template)

                        const surveySequence = findSurveySequenceByLanguage(data.content.content_template, i18n.language) 
                        setExistContentSurveySequence(surveySequence)

                        sortingSurvyesData = {
                            ...data,
                            content: {
                                ...data.content,
                                content_template: {
                                    ...data.content.content_template,
                                    survey_sequence: {
                                        ...data.content.content_template.survey_sequence,
                                        // surveys: data.content.content_template.survey_sequence?.surveys?.sort((a: any, b: any) => a.order - b.order),
                                        surveys: surveySequence.surveys?.sort((a: any, b: any) => a.order - b.order),
                                    }
                                }
                            }
                        }
                    } else {
                        sortingSurvyesData = {
                            ...data,
                            content: {
                                ...data.content,
                                survey_sequence: {
                                    ...data.content.survey_sequence,
                                    surveys: data.content.survey_sequence?.surveys?.sort((a: any, b: any) => a.order - b.order),
                                }
                            }
                        }

                    }



                    setMainState(prev => {
                        return { ...prev, surveySequence: sortingSurvyesData }
                    })

                    if (data.content.template_id) {
                        const surveySequence = findSurveySequenceByLanguage(data.content.content_template, i18n.language) 
                        // if (data.content.content_template.survey_sequence && data.content.content_template.survey_sequence[0].surveys?.length) {
                        if (surveySequence) {
                            // setTotalSurvey(data.content.survey_sequence.surveys.length)
                            setTotalSurvey(surveySequence.surveys.length)
                        }

                    } else {
                        if (data.content.survey_sequence && data.content.survey_sequence.surveys?.length) {
                            setTotalSurvey(data.content.survey_sequence.surveys.length)
                        }
                    }
                    // if (data.content.survey_sequence && data.content.survey_sequence.surveys?.length) {
                    //     setTotalSurvey(data.content.survey_sequence.surveys.length)
                    // }
                }
            }).catch(error => {
                console.log(error)
            }
            )
        }
    }, [mainState.contentSlug, participantSlug, setMainState])



    const { step, surveySequence } = mainState

    // const surveyList = surveySequence && surveySequence.content.survey_sequence?.surveys
    // const surveyList = surveySequence?.content.template_id ? surveySequence.content.content_template.survey_sequence[0]?.surveys : surveySequence && surveySequence.content.survey_sequence?.surveys
    let surveyList:any = []
    if(surveySequence?.content.template_id ) {
        surveyList = existContentSurveySequence?.surveys

    } else {
        surveyList = surveySequence && surveySequence.content.survey_sequence?.surveys
    }
    // const _surveySequence = findSurveySequenceByLanguage(surveySequence?.content.content_template , i18n.language) 
    // const surveyList = surveySequence?.content.template_id ? surveySequence.content.content_template.survey_sequence[0]?.surveys : surveySequence && surveySequence.content.survey_sequence?.surveys


    // console.log('list',surveyList)

    const handleStartSurveySequence = () => {
        setMainState(prev => {
            return { ...prev, step: 1 }
        })
    }

    const sendSurveyToServer = async () => {  

        const obj = {
            surveyData: { survey_response: selectedItem?.id },
            content: mainState.contentSlug,
            organ: currentParticipantOrganizationId,
            participant: participantSlug,
            uuid,
            survey_sequence: existContentSurveySequence ? existContentSurveySequence.id : mainState.surveySequence?.content.survey_sequence?.id,
            survey: surveyList && surveyList[currentSurvey].id
        }

        
        const baseURL = process.env.REACT_APP_API_URL
        const reqUrl = baseURL + `users/${obj.uuid}/submit-survey-sequence-response?organization=${currentParticipantOrganizationId}&participant=${obj.participant}&content=${obj.content}&survey_sequence=${obj.survey_sequence}&survey=${obj.survey}`

        return new Promise(async (resolve, reject) => {
            const response = await axios.put(reqUrl, {
                "survey_response": selectedItem?.id
            }, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
            if (response.status === 200) {
                resolve(true)
            }
            else {
                reject(false)
            }
        })
    }


    const handleSubmitSequence = async () => {
        // check and next to the step
        // checkAllSurveyesAreAnswered()
        if (currentSurvey < totalSurvey - 1) {
            // console.log('next survey + next step', currentSurvey, totalSurvey - 1)
            const isSent = await sendSurveyToServer()
            if (isSent) {
                setCurrentSurvey(prev => prev + 1)
            }
        }
        if (currentSurvey === totalSurvey - 1) {
            await sendSurveyToServer()
        }
    }

    const handleNextSequence = () => {
        // checkAllSurveyesAreAnswered()
        // check and next to the step
        if (currentSurvey < totalSurvey - 1) {
            setCurrentSurvey(prev => prev + 1)
        }
    }

    const setSurveyIsFinishedAndIntracted = async () => {
        const apiURl = process.env.REACT_APP_API_URL
        const reqUrl = `${apiURl}users/${uuid}/submit-content-interacted?organization=${currentParticipantOrganizationId}&participant=${participantSlug}&content=${mainState.contentSlug}`
        await axios.put(reqUrl, {
            "interacted": true
        }, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
    }

    const surveyId = surveyList && surveyList[currentSurvey].id
    const idSurvedAnswered = mainState.surveySequence?.response && mainState.surveySequence?.response.some(res => res.survey_id === surveyId)

    // console.log({ surveyId, idSurvedAnswered })
    // const allSurveyesAreAnswered = mainState.surveySequence?.response.some(survey => survey.survey_id === surveyId)
    // console.log('test',surveySequence?.content.tags[0])
    const checkAllOfSurveyAreAnswered = mainState.surveySequence?.response && mainState.surveySequence?.response.length === totalSurvey
    // mainState.surveySequence?.content.content_template.content_metadata[0].tags[0]
    let tag = ''
    if(existContentSurveySequence) {
        const findContentMetaDataByLang = mainState.surveySequence?.content.content_template.content_metadata.find((i:any) => i.language === i18n.language)
        if(findContentMetaDataByLang) {
            tag = findContentMetaDataByLang.tags[0] || ''
        } else {
            tag = mainState.surveySequence?.content.content_template.content_metadata[0].tags[0]
        }
    }

    return (
        <React.Fragment>
            {
                step === 0 ? (
                    <React.Fragment>
                        <div
                            className="relative p-4 my-6 text-black whitespace-pre-wrap bg-gray-100 rounded-md shadow-md">
                            <div className="flex items-center justify-center w-full align-center">
                                <div
                                    style={{ backgroundColor:mainState.baseColor }}
                                    className="absolute left-auto right-auto p-3 text-2xl text-white rounded-full shadow-md -top-5">
                                    {/* <MdNoteAlt className="text-white" /> */}
                                    <div className='w-5 h-5 text-white'>
                                    {ContentTemplateIcons.survey_sequence}
                                    </div>
                                </div>
                            </div>
                            <p className="pt-10 pb-4 text-sm text-center">
                                {
                                    existContentSurveySequence ?
                                    existContentSurveySequence.description
                                        : surveySequence?.content.survey_sequence?.description
                                }
                            </p>
                        </div>

                        {/* <p className='text-sm text-center text-green-400'>Thank you for completing the survey and providing your feedback!</p> */}
                        {checkAllOfSurveyAreAnswered ? (
                            <Button onClick={() => {
                                setMainState(prev => {
                                    return { ...prev, step: 1 }
                                })
                            }} icon={<RiSurveyFill className={"mr-2"} />}
                                label={t('reviewAnswers')}
                                customStyles={{ backgroundColor:mainState.baseColor }}
                                extraClasses={" text-white w-2/2 m-auto"}
                            />) : (
                            <Button onClick={handleStartSurveySequence} icon={<RiSurveyFill className={"mr-2"} />}
                                label={idSurvedAnswered ? `${t('continueSurveySequence')}` : `${t('startSurveySequence')}`}
                                customStyles={{ backgroundColor:mainState.baseColor }}
                                extraClasses={" text-white w-2/2 m-auto"}
                            />)}



                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {surveyList &&
                            surveyList[currentSurvey] &&
                            (
                                <div className="flex flex-col items-center px-4">
                                    <SurveySequenceHeader
                                        tagTitle={existContentSurveySequence ? tag : surveySequence?.content.tags[0] || ''}
                                        title={surveyList[currentSurvey].title}
                                        description={surveyList[currentSurvey].description}
                                    />

                                    {idSurvedAnswered ? surveyList[currentSurvey]?.survey_items.sort((a: any, b: any) => a.order - b.order).map((item: SurveyItem, idx: number) => {
                                        const isAnswered = mainState.surveySequence?.response && mainState.surveySequence?.response.some(res => res.survey_response === item.id)
                                        return (
                                            <div className="w-full pb-5 space-y-3" key={idx}>
                                                <button
                                                    disabled={!isAnswered}
                                                    className={`text-center w-full p-3 rounded-lg cursor-pointer ${selectedItem && selectedItem.id === item.id ? 'bg-slate-400' : ''} ${isAnswered === true ? 'bg-green-400' : 'bg-white'}`}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        if (isAnswered) {
                                                            return
                                                        }
                                                        
                                                        setSelectedItem(item)
                                                    }}>
                                                    <span className={`text-black`}>{item.label}
                                                    </span>
                                                </button>
                                            </div>
                                        )
                                    }) : surveyList[currentSurvey]?.survey_items.sort((a: any, b: any) => a.order - b.order).map((item: SurveyItem, idx: number) => {
                                        // const isAnswered = mainState.surveySequence?.response && mainState.surveySequence?.response.some(res => res.survey_response === item.id)
                                        return (
                                            <div className="w-full pb-5 space-y-3" key={idx}>
                                                <button
                                                    className={`text-center w-full p-3 rounded-lg cursor-pointer
                                                        ${selectedItem && selectedItem.id === item.id ? 'bg-slate-400' : 'bg-white'}
                                                        `}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        console.log('selected item',item)
                                                        setSelectedItem(item)
                                                    }}>
                                                    <span className={`text-black`}>{item.label}</span>
                                                </button>
                                            </div>
                                        )
                                    })}

                                    {currentSurvey === totalSurvey - 1 ? (
                                        <React.Fragment>
                                            <Button
                                                label={idSurvedAnswered === false ? `${t('submitAndExitSurvey')}` : `${t('exit')}`}
                                                // icon={<BsCheckAll className='mx-1' />}
                                                onClick={async () => {
                                                    if (idSurvedAnswered === false) {
                                                        // checkAllSurveyesAreAnswered(
                                                        const isSent = await sendSurveyToServer()
                                                        if (isSent) {
                                                            await setSurveyIsFinishedAndIntracted()
                                                            setMainState(prev => {
                                                                return { ...prev, step: 0, openContentPage: false }
                                                            })
                                                            // setCurrentSurvey(0)
                                                            // window.location.reload()
                                                            navigate(AppRoutes.STATE_FLOW)
                                                        }
                                                    } else {
                                                        setMainState(prev => {
                                                            return { ...prev, step: 0, openContentPage:false }
                                                        })
                                                        setCurrentSurvey(0)
                                                        // navigate('/')
                                                        navigate(AppRoutes.STATE_FLOW)
                                                    }

                                                }}

                                                extraClasses={"bg-[#FF6600]"} />

                                        </React.Fragment>
                                    ) : (
                                        <Button
                                            label={idSurvedAnswered ? `${t('next')}` : `${t('submitResponse')}`}
                                            icon={idSurvedAnswered ? <MdArrowForwardIos className='mx-1' /> : <MdCheckCircle className="mx-1" />}
                                            onClick={idSurvedAnswered ? handleNextSequence : handleSubmitSequence}
                                            extraClasses={"bg-[#FF6600] text-white"} />
                                    )}


                                    {/* active survey dots */}

                                    <div className='flex flex-row my-5'>
                                        {
                                            surveyList.map((item: any, idx: number) => {
                                                return (
                                                    <div         
                                                    key={idx} className={`w-3 h-3 mx-1 rounded-full ${currentSurvey === idx ? 'bg-organization-secondary' : 'bg-gray-300'}`}></div>
                                                )
                                            })
                                        }
                                    </div>



                                </div>
                            )
                        }
                    </React.Fragment>
                )
            }


        </React.Fragment>
    )
});

export default SurveySequence;