import API from "../index";
import { formData } from "../../types/Content";
import { getCurrentParticipantSlug } from "../../utils/LocalStorage";
import { getValue } from "../../services/offline";

export const getContent = async (organ: number, contentSlug: string, type: string, participantSlug?: any) => {

    // if (type === "survey_sequence") {
    //     return []
    // }
    // else {
        // console.log("amr get content start")
        if(navigator.onLine){
            let slug = participantSlug ? participantSlug : getCurrentParticipantSlug()
            const { data } = await API.get(`organizations/${organ}/contents/${contentSlug}/${type}?participant_id=${slug}`);
            // console.log("amr get content online data" , data)
            return data
        }else{
            // let offline_data = await getValue(`new-content-${participantSlug}`)
            let offline_data = await getValue(`${participantSlug}`)
            console.log("amr get content offline data" , offline_data)
            if (offline_data) {
                return offline_data
            }
        }
};

export const getSurvey = async (organ: number, contentSlug: string, participantSlug?: string) => {
    // console.log("amr survey " , contentSlug, participantSlug)
    if(navigator.onLine){
        let slug = participantSlug ? participantSlug : getCurrentParticipantSlug()
        const { data } = await API.get(`organizations/${organ}/contents/${contentSlug}/survey?participant_id=${slug}`);
        return data.data;
    }else{
        // let offline_data = await getValue(`new-content-${participantSlug}_${contentSlug}`)
        let offline_data = await getValue(`${contentSlug}`)
        console.log("amr offline data" , offline_data)
        if (offline_data) {
            
            return offline_data
        }
    }
}

export const getSurveySequence = async (organ: number, content_slug: string, participantSlug: string) => {
    const { data } = await API.get(`organizations/${organ}/contents/${content_slug}/survey_sequence?participant_id=${participantSlug}`);
    return data.data
}


export const getWorkerContent = async (uuid: string, organ: number, participant: string) => {
    const { data } = await API.get(`users/${uuid}/contents?organization=${organ}&participant=${participant}`);
    return data;
};

export const getNewContents = async (organ: number, uuid: string, participantSlug: string) => {


    let slug = participantSlug ? participantSlug : getCurrentParticipantSlug()

    const { data } = await API.get(`users/${uuid}/new-content?organization=${organ}&participant=${slug}`);
    return data;
};

export const getSeenContents = async (organ: number, uuid: string, participantSlug: string) => {
    let slug = participantSlug ? participantSlug : getCurrentParticipantSlug()
    const { data } = await API.get(`users/${uuid}/seen-content?organization=${organ}&participant=${slug}`);
    return data;
};

export const submitContent = async (formData: formData) => {
    const { data } = await API.put(`users/${formData.uuid}/submit-content-interacted?organization=${formData.organ}&participant=${formData.participantSlug}&content=${formData.contentSlug}`, { ...formData.data });
    return data;
};

export const submitConsent = async (formData: formData) => {
    const { data } = await API.put(`users/${formData.uuid}/submit-consent-response?organization=${formData.organ}&participant=${formData.participantSlug}&content=${formData.contentSlug}`, { ...formData.consentData });
    return data;
};

export const submitSurvey = async (formData: formData) => {
    const { data } = await API.put(`users/${formData.uuid}/submit-survey-response?organization=${formData.organ}&participant=${formData.participantSlug}&content=${formData.contentSlug}`, { ...formData.surveyData });
    return data;
};

export const submitSelector = async (formData: formData) => {
    const { data } = await API.put(`users/${formData.uuid}/submit-selector-response?organization=${formData.organ}&participant=${formData.participantSlug}&content=${formData.contentSlug}`, { ...formData.selectorData });
    return data;
};

export const submitSurveySeq = async (formData: formData) => {
    const { data } = await API.put(`users/${formData.uuid}/submit-survey-sequence-response?organization=${formData.organ}&participant=${formData.participantSlug}&content=${formData.contentSlug}&survey_sequence=${formData.survey_sequence}&survey=${formData.survey}`, { ...formData.surveyData });
    return data;
};