import {HiOutlineHome} from "react-icons/hi";
import Logo from "../assets/logo-light.png";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {store} from "../store/store";

const Candidate = () => {
    const navigation = useNavigate()
    const {state} = useContext(store);

    const handleCancel = () => navigation("/stateFlow")

    return (
        <div className="relative">
            <div className="z-20 absolute flex flex-col w-full justify-center">
                <div className="bg-fuchsia-700 w-full h-full py-3 flex flex-col justify-center">
                    <div className="flex items-center text-white justify-around">
                        <HiOutlineHome onClick={handleCancel} className="text-2xl "/>
                        <img src={Logo} className="h-6" alt="logo"/>
                        <p className="text-lg uppercase">Next</p>
                    </div>
                </div>

                <div className="p-4 space-y-6 flex flex-col items-center">
                    <img className="h-44 " src={state.user?.picture}
                         alt="user"/>
                    <p className="font-semibold text-lg">{state.user?.name}/12345</p>
                    <div className="text-base">
                        <p>Job Title:handler</p>
                        <p>Manager:""</p>
                        <p>HR Adviser:""</p>
                    </div>

                    <p className="text-4xl font-semibold text-gray-400">Employee</p>
                    <p className="font-semibold text-lg uppercase">Temporary Badge</p>

                </div>
            </div>
        </div>
    );
};

export default Candidate;