export const SurveySequenceIcon = (props: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden='true'
            className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
            viewBox="0 96 960 960"
        >
            <path fill="currentColor" d="M552.537 869.844q-20.463 0-34.577-14.114-14.114-14.114-14.114-34.578V330.848q0-20.464 14.114-34.578 14.114-14.114 34.577-14.114h53.77q20.464 0 34.578 14.114 14.114 14.114 14.114 34.578v490.304q0 20.464-14.114 34.578-14.114 14.114-34.578 14.114h-53.77zm-198.844 0q-20.464 0-34.578-14.114-14.114-14.114-14.114-34.578V330.848q0-20.464 14.114-34.578 14.114-14.114 34.578-14.114h53.77q20.463 0 34.577 14.114 14.114 14.114 14.114 34.578v490.304q0 20.464-14.114 34.578-14.114 14.114-34.577 14.114h-53.77zm-198.845 0q-20.848 0-34.77-13.922-13.922-13.922-13.922-34.77V330.848q0-20.848 13.922-34.77 13.922-13.922 34.77-13.922h53.77q20.463 0 34.577 14.114 14.114 14.114 14.114 34.578v490.304q0 20.464-14.114 34.578-14.114 14.114-34.577 14.114h-53.77zm596.534 0q-20.463 0-34.577-14.114-14.114-14.114-14.114-34.578V330.848q0-20.464 14.114-34.578 14.114-14.114 34.577-14.114h53.77q20.848 0 34.77 13.922 13.922 13.922 13.922 34.77v490.304q0 26.615-13.922 37.654-13.922 11.038-34.77 11.038h-53.77z"></path>
        </svg>
    )
}