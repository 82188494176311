export const AnnouncementIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 96 960 960"
      aria-hidden='true'
      className={`flex-shrink-0 transition duration-100 ${props.className || ""}`}
    >
      <path fill="currentColor" d="M457.308 553.154h45.384V303.693h-45.384v249.461zm22.641 126.23q11.551 0 19.416-7.814t7.865-19.365q0-11.551-7.814-19.417-7.814-7.865-19.365-7.865-11.551 0-19.416 7.814t-7.865 19.365q0 11.551 7.814 19.417 7.814 7.865 19.365 7.865zM100.001 937.536V253.694q0-23 17.082-40.346 17.082-17.347 40.611-17.347h644.612q23 0 40.346 17.347 17.347 17.346 17.347 40.346v484.612q0 23-17.347 40.346-17.346 17.347-40.346 17.347H241.539L100.001 937.536z"></path>
    </svg>
  )
};