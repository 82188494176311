import React, {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaArrowRight } from "react-icons/fa";

import TypeIcon from "../TypeIcon";
import { MainContext } from "../../context/MainProvider";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Theme";
import ZeroState from "../ZeroState";
import { getSeenContents } from "../../QueryHook/Contents/contents.apis";

interface SeenContentProps {
  participantSlug: string;
  uuid: string;
  onhandleContentsModal: (
    id?: number,
    type?: string,
    isInteracted?: boolean,
    contentSlug?: string
  ) => void;
}

const survey_sequence = "survey_sequence";

const SeenContent: FC<SeenContentProps> = memo((props) => {
  const { onhandleContentsModal, participantSlug, uuid } = props;

  const { mainState, setMainState } = useContext(MainContext);

  const { t, i18n } = useTranslation();

  const [contentSeenData, setContentSeenData] = useState<any>();
  const [isSeenDataLoading, setIsSeenDataLoading] = useState(false);

  const currentParticipantOrganizationId: number = useMemo(() => {
    // const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
    // return currentParticipant?.organization_id

    if (mainState.organization_id === 0) {
      const currentParticipant = mainState.allWorkerParticipants.find((participant: any) => participant.slug === participantSlug)
      return currentParticipant?.organization_id
    } else {
      return mainState.organization_id
    }
  }, [mainState.allWorkerParticipants, mainState.organization_id, participantSlug])

  useEffect(() => {
    setIsSeenDataLoading(true);
    if (!mainState.openContentPage) {
      // Perform API call
      getSeenContents(currentParticipantOrganizationId, uuid, participantSlug ?? "")
        .then((response) => {
          setContentSeenData(response);
          setIsSeenDataLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsSeenDataLoading(false);
        });
    } else {
      setIsSeenDataLoading(false);
    }
  }, [mainState.openContentPage, participantSlug, uuid]);

  const CardSubTransformer = (title: string) => {
    switch (title.toLowerCase()) {
      case "survey_sequence":
        return t("provideFeedback");
      case "article":
        return t("readArticle");
      case "video":
        return t("watchVideo");
      case "faq":
        return t("accessFAQ");
      case "checklist":
        return t("accessChecklist");
      case "announcement":
        return t("readAnnouncement");
      case "survey":
        return t("provideFeedback");
      case "consent":
        return t("giveConsent");
      case "direction":
        return t("direction");
      case "selector":
        return t("selector");
      default:
        return title;
    }
  };



  // =========================== Handlers
  const openContentItem = (item: any) => {
    if (item.ContentType === survey_sequence) {
      setMainState((prev) => {
        return {
          ...prev,
          // contentId: item.ContentID,
          contentSlug: item.ContentSlug,
          activate: true,
          surveys: null,
        };
      });
    } else if (item.ContentType === "survey") {
      setMainState((prev) => {
        return {
          ...prev,
          // surveyContentId: item.ContentID,
          surveyContentSlug: item.ContentSlug,
          activate: true,
        };
      });
    }
    onhandleContentsModal(
      item.ContentID,
      item.ContentType,
      true,
      item.ContentSlug
    );
  }


  const openContentByTitle = (item: any) => {
    setMainState((prev) => {
      return {
        ...prev,
        contentSlug: item.ContentSlug,
        activate: true,
      };
    });
  }


  if (isSeenDataLoading) {
    return (
      <div className="flex items-center justify-center flex-1 w-full p-2">
        <Loader isLoading={isSeenDataLoading} />
      </div>
    );
  }


  return (
    <div>
      {contentSeenData?.data ? (
        <>
          {contentSeenData?.data?.length === 0 || !contentSeenData?.data ? (
            <ZeroState />
          ) : (
            contentSeenData?.data
              ?.sort(
                (a: any, b: any) => a?.ContentPriority - b?.ContentPriority
              )
              ?.map((item: any, index: number) => {

                let title = item.ContentPreviewTitle
                let tag = ''

                if (item.ContentTemplate) {
                  const contentMetaDataLanguageExists = item.ContentTemplate.content_metadata.find((metaData: any) => metaData.language === i18n.language)
                  if (contentMetaDataLanguageExists) {
                    title = contentMetaDataLanguageExists.preview_title
                  } else {
                    title = item.ContentTemplate.content_metadata[0].preview_title
                  }
                  if (contentMetaDataLanguageExists) {
                    if (contentMetaDataLanguageExists.tags[0] !== "") {
                      tag = contentMetaDataLanguageExists.tags[0]
                    }
                  } else {
                    if (item.ContentTemplate.content_metadata[0].tags[0] !== "") {
                      tag = item.ContentTemplate.content_metadata[0].tags[0]

                    }
                  }
                } else {
                  if(item.ContentTag) {
                    tag = item.ContentTag[0]
                  } else {
                    tag = ""
                  }
                }


                return (
                  <div
                    key={index}
                    className="relative p-4 mt-8 rounded-sm shadow-xl text-general-text"
                    onClick={() => openContentItem(item)}
                  >
                    {item?.ContentTag && item.ContentTag.length > 0 && (
                      <span
                        style={{ backgroundColor: mainState.baseColor }}
                        className="text-white font-bold text-xs px-1 py-0.5 rounded  uppercase"
                      >
                        {tag}
                      </span>
                    )}

                    <p className="mt-2 text-lg leading-5 line-clamp-4">
                      {title}
                    </p>
                    <div className="flex items-center mt-2 text-xs">
                      {item.ContentType === survey_sequence ? (
                        <p
                          onClick={() => openContentByTitle(item)}
                          className="inline pl-1 uppercase"
                        >
                          {t("provideFeedback")}
                        </p>
                      ) : (
                        <p className="inline pl-1 uppercase">
                          {CardSubTransformer(item.ContentType)}
                        </p>
                      )}

                      <FaArrowRight className="ml-3 capitalize" />
                    </div>
                    <div
                      style={{ backgroundColor: mainState.baseColor }}
                      className="absolute top-0 right-0 flex items-center justify-center w-10 h-10 mr-4 -mt-3 rounded-full "
                    >
                      <TypeIcon type={item.ContentType?.toLowerCase()} />
                    </div>
                  </div>
                )
              })
          )}
        </>
      ) : null}
    </div>
  );
});

export default SeenContent;
